import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  cart: [],
  order: null,
  currentData: {
    contactInformation: {},
    debtors: [],
    securedParties: [],
    collateral: "",
    extra: {},
    addendums: [],
    currentDebtor: 0,
    currentSecuredParty: 0,
    currentAddendum: 0,
  },
  currentFiling: 0,
};

const dataDefault = {
  contactInformation: {},
  debtors: [],
  securedParties: [],
  collateral: "",
  extra: {},
  addendums: [],
  currentDebtor: 0,
  currentSecuredParty: 0,
  currentAddendum: 0,
};

export const ucc1Slice = createSlice({
  name: "UCC1",
  initialState: { ...defaultState },
  reducers: {
    updateContactInformation: (state, action) => {
      state.currentData.contactInformation = action.payload;
    },
    addADebtor: (state, action) => {
      state.currentData.debtors.push(action.payload);
    },
    deleteDebtor: (state, action) => {
      state.currentData.debtors = state.currentData.debtors.filter(
        (_, i) => i !== action.payload
      );
    },
    deleteSecuredParty: (state, action) => {
      state.currentData.securedParties =
        state.currentData.securedParties.filter((_, i) => i !== action.payload);
    },
    addASecuredParty: (state, action) => {
      state.currentData.securedParties.push(action.payload);
    },
    updateSpecificDebtor: (state, action) => {
      state.currentData.debtors[state.currentData.currentDebtor] =
        action.payload;
    },
    updateSecuredParties: (state, action) => {
      state.currentData.securedParties = action.payload;
    },
    updateSpecificSecuredParty: (state, action) => {
      state.currentData.securedParties[state.currentData.currentSecuredParty] =
        action.payload;
    },
    updateCollateral: (state, action) => {
      state.currentData.collateral = action.payload;
    },
    updateExtra: (state, action) => {
      state.currentData.extra = action.payload;
    },
    updateAddendums: (state, action) => {
      state.currentData.addendums = action.payload;
    },
    updateSpecificAddendum: (state, action) => {
      state.currentData.addendums[action.payload.id] = action.payload;
    },
    addAAddendum: (state, action) => {
      state.currentData.addendums.push(action.payload);
    },
    addFileToCart: (state, action) => {
      state.cart.push(action.payload);
    },
    updateFileInCart: (state, action) => {
      state.cart[state.currentFiling] = action.payload;
    },
    deleteFileFromCart: (state, action) => {
      if (state.cart[action.payload.id]) state.cart[action.payload.id] = null;
    },
    updateCurrentDebtor: (state, action) => {
      state.currentData.currentDebtor = action.payload;
    },
    updateCurrentSecuredParty: (state, action) => {
      state.currentData.currentSecuredParty = action.payload;
    },
    updateCurrentAddendum: (state, action) => {
      state.currentData.currentAddendum = action.payload;
    },
    updateCurrentFiling: (state, action) => {
      state.currentFiling = action.payload;
    },
    resetCurrentData: (state, action) => {
      state.currentData = dataDefault;
    },
    resetState: (state, action) => {
      state.cart = [];
      state.order = null;
      state.currentData = dataDefault;
      state.currentFiling = 0;
    },
    createANewOrder: (state, action) => {
      state.order = {
        orderNumber: Math.floor(Math.random() * 10000000).toString(),
        data: { ...state.cart },
      };
    },
  },
});

export const {
  updateContactInformation,
  updateSecuredParties,
  updateCollateral,
  updateExtra,
  updateAddendums,
  addFileToCart,
  updateFileInCart,
  deleteFileFromCart,
  addADebtor,
  updateCurrentDebtor,
  updateSpecificDebtor,
  deleteSecuredParty,
  deleteDebtor,
  addASecuredParty,
  updateCurrentSecuredParty,
  updateSpecificSecuredParty,
  addAAddendum,
  updateSpecificAddendum,
  currentAddendum,
  currentFiiling,
  updateCurrentFiling,
  resetCurrentData,
  createANewOrder,
  resetState,
} = ucc1Slice.actions;

export default ucc1Slice.reducer;
