import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    search_val: '',
    search_field:'',
    route: '',
};

export const searchSlice = createSlice({
  name: "Search",
  initialState: { ...defaultState },
  reducers: {
    updateSearchState: (state, action) => {
      console.log('updateSearchState: action.payload: ', action.payload)
      state.search_val = action.payload.search_val;
      state.search_field = action.payload.main_search_field;
      state.route = action.payload.route;
      console.log('updateSearchState: state: ', state.search_val)
      console.log('updateSearchState: state: ', state.route)
      console.log('updateSearchState: search_field',state.search_field);
    },
  },
});


export const {
  updateSearchState
} = searchSlice.actions;

export default searchSlice.reducer;
