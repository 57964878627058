import React, { useState } from 'react';
import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";

const EmployeeTable = ({ employees,isPrev}) => {
    const [showAllRows, setShowAllRows] = useState(false);
    let tableHead = <tr>
        <th>Name</th>
        <th>Position</th>
        <th>Email</th>
        <th>Number</th>
        <th>Address</th>
        <th>LinkedIn Url</th>
    </tr>;
    let num_rows = isPrev ? 4 : 10;
    let tableBody = (showAllRows ? employees : employees.slice(0, num_rows)).map((employee, index) => (
        <tr key={index}>
            <td className={'employee-name'}>{employee.name}</td>
            <td>{employee.position}</td>
            <td><a href={`mailto:${employee.email}`} className="email-link">{employee.email}</a></td>
            <td className={'employee-number'}>{employee.number}</td>
            <td>{employee.address}</td>
            <td className={'employee-linkedin'}><a href={employee.linkedinUrl}>LinkedIn</a></td>
        </tr>
    ));
    if (isPrev) {
        tableHead = <tr>
            <th>Name</th>
            {/*<th>Position</th>*/}
            <th>Email</th>
            {/*<th>Number</th>*/}
        </tr>;
        tableBody = (showAllRows ? employees : employees.slice(0, num_rows)).map((employee, index) => (
            <tr key={index}>
                <td className={'employee-name'}>{employee.name}</td>
                {/*<td>{employee.position}</td>*/}
                <td><a href={`mailto:${employee.email}`} className="email-link">{employee.email}</a></td>
                {/*<td className={'employee-number'}>{employee.number}</td>*/}
            </tr>
        ));
    }

    return (
        <div className="employees">
            <h2 className="employees-heading">Employees</h2>
            <table className="employees-table">
                <thead>
                {tableHead}
                </thead>
                <tbody>
                {tableBody}
                </tbody>
            </table>
            {employees.length > num_rows && (
                <div className="dropdown-container">
                    <button
                        className="dropdown-button"
                        onClick={() => setShowAllRows(!showAllRows)}
                    >
                        {showAllRows ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                </div>
            )}
        </div>
    );
};

export default EmployeeTable;
