import React, {useState,useRef,useEffect} from "react";
import { Document, Page,pdfjs } from 'react-pdf';
import { IoIosArrowRoundBack,IoIosArrowForward,IoIosArrowBack } from "react-icons/io";
// CSS
import "../../css/SearchResult.css";
import {useHistory} from "react-router-dom";
//Components
import SearchPreview from "./SearchPreview";



const SearchResult = ({location}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [originalPageWidth, setOriginalPageWidth] = useState(0);
    const [originalPageHeight, setOriginalPageHeight] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    let page_preview_details = location.page_preview_details.page_preview_details
    let {
        isMain,
        state,
        dispatch,
    } = page_preview_details;
    let [img_url,set_img_url] = useState(state.previewData.img_url);
    img_url = clean_url(img_url);

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


    let history = useHistory();

    function clean_url(url){
        url = (url !== null && url !== '' && url) ? "https://pitamodata-pub.s3.amazonaws.com/"+url : '';
        url = url.replace('tif','pdf');
        // Check if the URL ends with '.pdf', if not, add '.pdf'
        if (!url.endsWith('.pdf')) {
            url += '.pdf';
        }
        return url;
    }


    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
                setContainerHeight(containerRef.current.offsetHeight);
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Set the initial width
        handleResize();

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures this runs once on mount and unmount


    const updateImageUrl = (newUrl) => {
        img_url = newUrl;
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    function goToNextPage(){
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function onPageLoadSuccess(page) {
        setOriginalPageWidth(page.originalWidth);
        setOriginalPageHeight(page.originalHeight);
    }

    var page_component = (originalPageWidth < originalPageHeight) ? <Page
        className="SearchResult_Page"
        pageNumber={pageNumber}
        height={containerHeight*.9}
        renderTextLayer={false}
        wrap={false}
        renderAnnotationLayer={false}
        onLoadSuccess={onPageLoadSuccess}
    /> :<Page
        className="SearchResult_Page"
        pageNumber={pageNumber}
        width={600}
        renderTextLayer={false}
        wrap={false}
        renderAnnotationLayer={false}
        onLoadSuccess={onPageLoadSuccess}
    />

    function buildPDF() {
        return (
            <div className="SearchResult_PDFViewer" ref={containerRef}>
                <div className="pdf-wrapper">
                    <Document
                        className="SearchResult_Document"
                        file={img_url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode="svg"
                        style={{ textContent: 'none' }}
                    >
                        {page_component}
                    </Document>
                </div>
                <nav className="SearchResult_PDFViewer_Nav">
                    <IoIosArrowBack
                        className="SearchResult_PDFViewer_Arrow"
                        onClick={goToPrevPage}
                    />
                    <p>Page {pageNumber} of {numPages}</p>
                    <IoIosArrowForward
                        className="SearchResult_PDFViewer_Arrow"
                        onClick={goToNextPage}
                    />
                </nav>
            </div>
        );
    }
    page_preview_details = {
        'isMain': false,
        'state': state,
        'dispatch': dispatch,
    }
    return (
      <div className="main-container">
          <div className="SearchResult_SearchResult">
              <div className="Search_Result_col1">
                <a className="Search_Result_col1_back" onClick={history.goBack}>
                    <IoIosArrowRoundBack size={26} style={{height: '35px', width:'35px',paddingTop:'3px'}}/> Search Results
                </a>
                {<SearchPreview
                    location={page_preview_details}
                    updateImageUrl={(newImageUrl) => set_img_url(newImageUrl)}
                />
                }
              </div>
              {buildPDF()}
          </div>
      </div>
  );
};

export default SearchResult;
