import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createANewOrder,
  resetCurrentData,
  updateCurrentFiling,
} from "../../../redux/reducers/ucc3Slice";
import Checkout from "../Shared/Checkout";
import FilingCheckoutTable from "../Shared/FilingCheckoutTable";

import { postUccInDynamoDb } from "../../../api/dynamodb";

const CheckoutUCC3Container = ({ route }) => {
  const { cart, currentFiling } = useSelector((state) => state.ucc3);
  const dispatch = useDispatch();

  const cartIsEmpty = cart.length > 0 ? false : true;
  const completeRoute = route.complete;
  const addAnotherItemText = "Add Filing";
  const addAnotherItemRoute = route.addAFiling;

  const uploadCartToDynamodb = () => {
    cart.map((item) => {
      let temp = {
        documentNumber: Math.floor(Math.random() * 1000000).toString(),
        ...item,
      };
      temp.documentNumber = item.fileFound.img_url
        .split("fn=")[1]
        .split("&rn")[0];
      return postUccInDynamoDb(temp);
    });
  };

  const completeTransaction = () => {
    // If cart is empty, you shouldn't be able to checkout.
    if (cartIsEmpty) return console.log("Cart was empty: ", cart);
    console.log(...cart);
    dispatch(createANewOrder());
    uploadCartToDynamodb();
  };

  const deleteItem = (id) => {};
  const addAnotherItem = () => {
    // If cart is empty, adding one will cause two items to be created.
    let filingNumber = cartIsEmpty ? currentFiling : currentFiling + 1;

    // Prevents user from continously hitting Add Filing and then also hitting back button to do it again.
    if (cart.length !== currentFiling) filingNumber = currentFiling - 1;

    dispatch(updateCurrentFiling(filingNumber));
    dispatch(resetCurrentData());
  };

  return (
    <Checkout
      cart={cart}
      completeTransaction={completeTransaction}
      completeRoute={completeRoute}
      deleteItem={deleteItem}
      addAnotherItem={addAnotherItem}
      addAnotherItemText={addAnotherItemText}
      addAnotherItemRoute={addAnotherItemRoute}
      body={<FilingCheckoutTable cart={cart} />}
    />
  );
};

export default CheckoutUCC3Container;
