import React from "react";

// Components
import { Grid } from "@mui/material";

const AssignmentDAndSPContainer = ({
  title,
  data,
  view,
  deleteDebtorOrSecuredParty,
}) => {
  // Variables
  const name = data?.name || data[0];
  const address = data?.addressOne || data[1];

  return (
    <Grid item xs={11}>
      <Grid container border="1px solid grey" padding={1} maxHeight={200}>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container paddingLeft={1}>
                <Grid item xs={12}>
                  <Grid container>
                    <p>{title}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container paddingLeft={1}>
                <Grid item xs={12}>
                  <p>{name}</p>
                </Grid>
                <Grid item xs={12}>
                  <p>{address}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={12}>
              <button className="FilingSystemButton" onClick={view}>
                View
              </button>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <button
                  className="FilingSystemButton"
                  onClick={deleteDebtorOrSecuredParty}
                >
                  Delete
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignmentDAndSPContainer;
