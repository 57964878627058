import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  cart: [],
  order: null,
  currentFiling: 0,
  currentData: {
    fileFound: null,
    findUCC3: {},
    contactInformation: {},
    miscAmendment: {
      collateral: null,
    },
    debtors: [],
    securedParties: [],
    currentSecuredParty: 0,
    currentDebtor: 0,
  },

  // Used Just in case API is down and we need to test things.
  exampleFindUCC3File: {
    status: "LAPSED",
    date_filed: "06/24/2004",
    expires: "06/24/2014",
    filings_thru: "10/22/2021",
    secured_parties: [
      [
        "LANDMARK BANK, N.A.",
        "6300 N.E. FIRST AVENUE FORT LAUDERDALE FL 33334",
      ],
    ],
    debtors: [
      ["MCQUEEN SCOTT R.", "431 EAST COCONUT PALM ROAD BOCA RATON FL 33432"],
      [
        "PESTRICHELLI RICHARD H.",
        "5120 S.W. 111 TERRACE FORT LAUDERDALE FL 33338",
      ],
      ["ROSCHMAN JEFFERY S.", "2511 DEL LAGO DRIVE FORT LAUDERDALE FL 33316"],
    ],
    total_pgs: 9,
    img_url:
      "RetrieveImage.aspx?sst=&sov=6&sot=&st=20040725576X&fn=20040725576X&rn=1&ii=&ft=1&epn=",
    type: "UCC1",
    pages: 5,
    events_filed: 2,
    history: [
      {
        doc_num: "200900704169",
        img_url: "RetrieveImage.aspx?fn=200900704169",
        type: "UCC3",
        date: "06/16/2009",
        pages: "3",
        actions: "1",
        status: ["\n1) Continuation\n\n"],
      },
      {
        doc_num: "20090067376X",
        img_url: "RetrieveImage.aspx?fn=20090067376X",
        type: "UCC3",
        date: "06/09/2009",
        pages: "1",
        actions: "1",
        status: ["\n1) Continuation\n\n"],
      },
    ],
    collateral: "See Exhibit A attached hereto\n\f",
  },
};

const currentDataDefault = {
  fileFound: null,
  findUCC3: {},
  contactInformation: {},
  miscAmendment: {
    collateral: null,
  },
  debtors: [],
  securedParties: [],
  currentSecuredParty: 0,
  currentDebtor: 0,
};

export const ucc3Slice = createSlice({
  name: "UCC3",
  initialState: { ...defaultState },
  reducers: {
    // If a file searched was found then store it.
    setFileFound: (state, action) => {
      if (action.payload){
        state.currentData.fileFound = action.payload;
        state.currentData.debtors = state.currentData.fileFound.debtors;
        state.currentData.securedParties =
          state.currentData.fileFound.secured_parties;
      }else{
        state.currentData.fileFound = false
      }
    },

    // Store state of the FindUCC3 file.
    updateFindUCC3State: (state, action) => {
      state.currentData.findUCC3 = action.payload;
    },

    // Store state of the Contact Information file.
    updateContactInformationState: (state, action) => {
      state.currentData.contactInformation = action.payload;
    },

    // Add a new secured party to the securedParties array.
    addASecuredParty: (state, action) => {
      state.currentData.securedParties.push(action.payload);
    },

    // Remove a secured party from the securedParties array.
    deleteSecuredParty: (state, action) => {
      state.currentData.securedParties =
        state.currentData.securedParties.filter((_, i) => i !== action.payload);
    },

    // Delete a debtor from the debtors array.
    deleteDebtor: (state, action) => {
      state.currentData.debtors = state.currentData.debtors.filter(
        (_, i) => i !== action.payload
      );
    },

    // Add another debtor to the debtors array.
    addADebtor: (state, action) => {
      state.currentData.debtors.push(action.payload);
    },

    // Update the currentDebtor to the debtor that is being viewed.
    updateCurrentDebtor: (state, action) => {
      state.currentData.currentDebtor = action.payload;
    },

    // Update the currentSecuredParty to the secured party that is being viewed.
    updateCurrentSecuredParty: (state, action) => {
      state.currentData.currentSecuredParty = action.payload;
    },

    // Update a specific debtor with data from the user.
    updateSpecificDebtor: (state, action) => {
      state.currentData.debtors[state.currentData.currentDebtor] =
        action.payload;
    },

    // Update a specific secured party with data from the user.
    updateSpecificSecuredParty: (state, action) => {
      state.currentData.securedParties[state.currentData.currentSecuredParty] =
        action.payload;
    },

    // Update the data in miscAmendment.
    updateMiscAmendment: (state, action) => {
      state.currentData.miscAmendment = action.payload;
    },

    // Update the data from the Authorization file.
    updateAuthorization: (state, action) => {
      state.currentData.authorization = action.payload;
    },

    // Add the data stored from the user into the cart for checkout as a single object.
    addCurrentDataToCart: (state, action) => {
      state.cart.push(state.currentData);
    },

    // Adds another data item to the cart.
    addFileToCart: (state, action) => {
      state.cart.push(action.payload);
    },

    // Reset slices state to default data structure for checkout.
    resetCurrentData: (state, action) => {
      state.currentData = currentDataDefault;
    },

    // Updates the current filing to the one the user wants to view.
    updateCurrentFiling: (state, action) => {
      state.currentFiling = action.payload;
    },
    resetState: (state, action) => {
      state.cart = [];
      state.order = null;
      state.currentData = currentDataDefault;
      state.currentFiling = 0;
    },
    // Stores the order number and cart into order object.
    createANewOrder: (state, action) => {
      state.order = {
        orderNumber: Math.floor(Math.random() * 10000000).toString(),
        data: { ...state.cart },
      };
    },
  },
});

export const {
  setFileFound,
  updateFindUCC3State,
  updateContactInformationState,
  deleteSecuredParty,
  deleteDebtor,
  addADebtor,
  addASecuredParty,
  updateCurrentSecuredParty,
  updateCurrentDebtor,
  updateSpecificSecuredParty,
  updateSpecificDebtor,
  updateMiscAmendment,
  updateAuthorization,
  addCurrentDataToCart,
  addFileToCart,
  resetCurrentData,
  updateCurrentFiling,
  resetState,
  createANewOrder,
} = ucc3Slice.actions;

export default ucc3Slice.reducer;
