// Pages
import SecuredPartyForm from "../Shared/SecuredPartyInformation";

// Redux
import {
  addASecuredParty,
  updateCurrentSecuredParty,
  updateSpecificSecuredParty,
} from "../../../redux/reducers/ucc1Slice";
import { useSelector, useDispatch } from "react-redux";

const SecuredParties = ({ nextToAssignmentList }) => {
  // Redux State
  const securedParties = useSelector(
    (state) => state.ucc1.currentData.securedParties
  );
  const currentSecuredParty = useSelector(
    (state) => state.ucc1.currentData.currentSecuredParty
  );
  const dispatch = useDispatch();

  // Methods
  const saveSecuredParty = (data) => dispatch(updateSpecificSecuredParty(data));
  const submitSecuredParty = (data) => {
    saveSecuredParty(data);
    nextToAssignmentList();
  };
  const addAnotherSecuredParty = (data) => {
    saveSecuredParty(data);
    dispatch(addASecuredParty({}));
    dispatch(updateCurrentSecuredParty(currentSecuredParty + 1));
  };

  return (
    <SecuredPartyForm
      data={securedParties[currentSecuredParty]}
      submitSecuredParty={submitSecuredParty}
      addAnotherSecuredParty={addAnotherSecuredParty}
      UCC1={true}
      id={currentSecuredParty}
    />
  );
};

export default SecuredParties;
