// Error Checking
const performAPIErrorChecking = (result, type) => {
  console.log(`Error of API Request ${type}: `, result);

  if (result.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("ERROR RESPONSE DATA >>> ", result.response.data);
    console.log("ERROR RESPONSE STATUS >>> ", result.response.status);
    console.log("ERROR RESPONSE HEADERS >>> ", result.response.headers);
  } else if (result.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("ERROR REQUEST >>> ", result.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("ERROR MESSAGE >>> ", result.message);
  }
  console.log("ERROR CONFIG >>> ", result.config);

  result.error = true;
  return result;
};

// Checking Result of Search.
const performAPIResultsFound = (result, type) => {
  console.log(`Result of API Request ${type}: `, result);
  const data = result.data;

  if (data.length > 0) return result;
  if (data.length <= 0) return result;
};

export { performAPIErrorChecking, performAPIResultsFound };
