// Pages
import DebtorForm from "../Shared/DebtorInformation";

// Redux
import {
  addADebtor,
  updateCurrentDebtor,
  updateSpecificDebtor,
} from "../../../redux/reducers/ucc1Slice";
import { useSelector, useDispatch } from "react-redux";

const Debtors = ({ nextToSecuredParty }) => {
  // Redux State
  const debtors = useSelector((state) => state.ucc1.currentData.debtors);
  const currentDebtor = useSelector(
    (state) => state.ucc1.currentData.currentDebtor
  );
  const dispatch = useDispatch();

  // Methods
  const saveDebtor = (data) => dispatch(updateSpecificDebtor(data));
  const submitDebtor = (data) => {
    saveDebtor(data);
    nextToSecuredParty();
  };
  const addAnotherDebtor = (data) => {
    dispatch(addADebtor(data));
    updateCurrentDebtor(currentDebtor + 1);
  };

  return (
    <DebtorForm
      data={debtors[currentDebtor]}
      submitDebtor={submitDebtor}
      addADebtor={addAnotherDebtor}
      UCC1={true}
      id={currentDebtor}
    />
  );
};

export default Debtors;
