import React, {useEffect, useState} from "react";

// React Router
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";

// Pages
import FilingSystem from "./Pages/FilingSystem/FilingSystem";
import UCC1 from "./Pages/FilingSystem/UCC1/UCC1";
import UCC3 from "./Pages/FilingSystem/UCC3/UCC3";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import SearchRouter from "./Pages/Search/SearchRouter";
import HowItWorksPage from "./Pages/HowItWorksPage";
import AboutUs from "./Pages/AboutUs";
import SearchResult from "./Pages/Search/SearchResult";
import LeadSearch from "./Pages/Search/Lead/LeadSearch";
import LeadView from "./Pages/Search/Lead/LeadView";
import LandingPage from "./Pages/LandingPage";


// Redux
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {Auth} from "aws-amplify";

const Routes = () => {
  // Redux State
  // const user = useSelector((state) => state.user.user);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let home;
  if (user === null) {
    home = LandingPage
  }else{
    home = Home
  }
  useEffect(() => {
    Auth.currentAuthenticatedUser()
        .then(user => setUser(user))
        .catch(err => setUser(null))
        .finally(() => setLoading(false));
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route path="/SearchRouter" component={SearchRouter} />
        <Route path="/SearchResult" component={SearchResult} />
        <Route path="/HowItWorksPage" component={HowItWorksPage} />
        <Route path="/AboutUs" component={AboutUs} />
        <Route path="/LeadSearch" component={LeadSearch} />
        <Route path="/LeadView" component={LeadView} />
        <Route path="/FilingSystem" component={FilingSystem} />
        <Route path="/UCC1" component={UCC1} />
        <Route path="/UCC3" component={UCC3} />
        <Route path="/Home" component={Home} />
        <Route path="/" component={home} />
      </Switch>

      {/*{!user && <Redirect to="/Login" />}*/}
      {/*{user && <Redirect to="/" />}*/}
    </Router>
  );
};

export default Routes;
