import React, { useCallback, useEffect } from "react";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateMiscAmendment } from "../../../redux/reducers/ucc3Slice";

const MiscAmendment = ({ route }) => {
  // Redux State
  const { miscAmendment, fileFound } = useSelector(
    (state) => state.ucc3.currentData
  );
  const dispatch = useDispatch();

  // State
  const [noneCheck, setNoneCheck] = useState(miscAmendment?.noneCheck || false);
  const [deleteCheck, setDeleteCheck] = useState(
    miscAmendment?.deleteCheck || false
  );
  const [addCheck, setAddCheck] = useState(miscAmendment?.addCheck || false);
  const [
    restateCollateralDescriptionCheck,
    setRestateCollateralDescriptionCheck,
  ] = useState(miscAmendment?.restateCollateralDescriptionCheck || false);
  const [assignCollateralCheck, setAssignCollateralCheck] = useState(
    miscAmendment?.assignCollateralCheck || false
  );
  const [collateral, setCollateral] = useState(
    miscAmendment?.collateral || fileFound?.collateral
  );

  // Methods
  const getDataTree = useCallback(() => {
    return {
      noneCheck: noneCheck,
      deleteCheck: deleteCheck,
      addCheck: addCheck,
      restateCollateralDescriptionCheck: restateCollateralDescriptionCheck,
      assignCollateralCheck: assignCollateralCheck,
      collateral: collateral,
    };
  }, [
    addCheck,
    assignCollateralCheck,
    collateral,
    deleteCheck,
    noneCheck,
    restateCollateralDescriptionCheck,
  ]);

  // Events
  const onNoneCheckChanged = () => setNoneCheck(!noneCheck);
  const onDeleteCheckChanged = () => setDeleteCheck(!deleteCheck);
  const onAddCheckChanged = () => setAddCheck(!addCheck);
  const onRestateCollateralDescriptionCheckChanged = () =>
    setRestateCollateralDescriptionCheck(!restateCollateralDescriptionCheck);
  const onAssignCollateralCheckChanged = () =>
    setAssignCollateralCheck(!assignCollateralCheck);
  const onCollateralChanged = (e) => setCollateral(e.target.value);

  useEffect(() => {
    dispatch(updateMiscAmendment(getDataTree()));
  }, [dispatch, getDataTree]);

  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        {/* Title */}
        <FilingSystemTitle title="Miscellaneous Amendment" />

        {/* Checkboxes */}
        <Grid item xs={11} md={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={0} md={3}></Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <div>
                  <input
                    type="checkbox"
                    id="none"
                    checked={noneCheck}
                    onChange={onNoneCheckChanged}
                  />
                  <label htmlFor="none">None</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="delete"
                    checked={deleteCheck}
                    onChange={onDeleteCheckChanged}
                  />
                  <label htmlFor="delete">Delete</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="add"
                    checked={addCheck}
                    onChange={onAddCheckChanged}
                  />
                  <label htmlFor="add">Add</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="restate-collateral-description"
                    checked={restateCollateralDescriptionCheck}
                    onChange={onRestateCollateralDescriptionCheckChanged}
                  />
                  <label htmlFor="restate-collateral-description">
                    Restate Collateral Description
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="assign-collateral"
                    checked={assignCollateralCheck}
                    onChange={onAssignCollateralCheckChanged}
                  />
                  <label htmlFor="assign-collateral">Assign Collateral</label>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={0} md={3}></Grid>
          </Grid>
        </Grid>

        {/* Collateral Information Title */}
        <FilingSystemTitle title="What's the collateral?" />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={9} md={4}>
              <textarea
                type="textArea"
                className="FilingSystemTextArea"
                id="collateral"
                value={collateral}
                onChange={onCollateralChanged}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} sm={9} md={4}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <Link to={route.next}>
                <button className="FilingSystemButton">Next</button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MiscAmendment;
