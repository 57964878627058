import React, { useCallback, useEffect, useState } from "react";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import LabelAndSelect from "../../../Components/LabelAndSelect";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateAuthorization } from "../../../redux/reducers/ucc3Slice";

const Authorization = ({ route, addToCart }) => {
  // Redux State
  const authorization = useSelector(
    (state) => state.ucc3.currentData.authorization
  );
  const dispatch = useDispatch();

  // State
  const [selectName, setSelectName] = useState(
    authorization?.selectName || "Select Name"
  );
  const [debtorCheck, setDebtorCheck] = useState(
    authorization?.debtorCheck || false
  );
  const [securedPartyCheck, setSecuredPartyCheck] = useState(
    authorization?.securedPartyCheck || false
  );
  const [referenceData, setReferenceData] = useState(
    authorization?.referenceData || ""
  );

  // Methods
  const getDataTree = useCallback(() => {
    return {
      selectName: selectName,
      debtorCheck: debtorCheck,
      securedPartyCheck: securedPartyCheck,
      referenceData: referenceData,
    };
  }, [debtorCheck, referenceData, securedPartyCheck, selectName]);

  // Events
  const onCompleteClicked = () => addToCart();
  const onUploadFilesClicked = () => {};
  const onDebtorCheckChanged = () => setDebtorCheck(!debtorCheck);
  const onReferenceDataChanged = (e) => setReferenceData(e.target.value);
  const onSecuredPartyCheckChanged = () =>
    setSecuredPartyCheck(!securedPartyCheck);

  // Updates data in Redux
  useEffect(() => {
    dispatch(updateAuthorization(getDataTree()));
  }, [dispatch, getDataTree]);

  return (
    <div class="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        {/* Title */}
        <FilingSystemTitle title="Authorization" />

        {/* Checkboxes */}
        <Grid item xs={11} md={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={0} md={3}></Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                gap={7}
              >
                <div>
                  <input
                    type="checkbox"
                    id="debtor"
                    checked={debtorCheck}
                    onChange={onDebtorCheckChanged}
                  />
                  <label htmlFor="debtor">Debtor</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="secured-party"
                    checked={securedPartyCheck}
                    onChange={onSecuredPartyCheckChanged}
                  />
                  <label htmlFor="secured-party">Secured Party</label>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={0} md={3}></Grid>
          </Grid>
        </Grid>

        {/* Select */}
        <Grid item xs={11} md={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={5}
          >
            <Grid item xs={12} sm={9} md={4}>
              <LabelAndSelect
                value={selectName}
                setValue={setSelectName}
                options={["Select Name", "Caleb Heinzman", "Seth Heinzman"]}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={9} md={4}>
              <Grid
                container
                className="FilingSystemTitle"
                justifyContent="start"
                fontSize="large"
                marginBottom={1}
              >
                Reference Data
              </Grid>
              <div>
                <textarea
                  type="textArea"
                  className="FilingSystemTextArea"
                  id="collateral"
                  value={referenceData}
                  onChange={onReferenceDataChanged}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} sm={9} md={4}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <button
                className="FilingSystemButton"
                onClick={onUploadFilesClicked}
              >
                Upload Files
              </button>
            </Grid>
            <Grid item xs={6}>
              <Link to={route.complete}>
                <button
                  className="FilingSystemButton"
                  onClick={onCompleteClicked}
                >
                  Complete
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Authorization;
