import React from "react";

// CSS

// Pages
import SearchPage from "./SearchPage";
import SearchResult from "./SearchResult";
import LeadSearch from "./Lead/LeadSearch";
import LeadView from "./Lead/LeadView";


// React Router
import { Redirect, Route, Switch } from "react-router-dom";

const SearchRouter = ({location}) => {
  console.log("SearchRouter", location)
  // State
  let search_val = location.search_val || location.value || '';
  let search_field = location.search_field || '';
  let lead_type = location.lead_type || '';
  let is_debtor = location.search_field || '';
  // List of pages in Search.
  const pageRoutes = {
    SearchPage: {
      self: "/SearchRouter/SearchPage",
      next: "/SearchRouter/SearchResult",
    },
    SearchResult: {
      self: "/SearchRouter/SearchResult",
    },
    LeadSearch: {
      self: "/SearchRouter/LeadSearch",
      next: "/SearchRouter/LeadView",
    },
    LeadView: {
      self: "/SearchRouter/LeadView",
    }
  };
  return (
    <div>
      {/* Content */}
      <div className="">
        <Switch>
          <Route path={pageRoutes.SearchPage.self}>
            <SearchPage search_val={search_val}
                        search_field={search_field}
                        route={pageRoutes.SearchPage}/>
          </Route>
          <Route path={pageRoutes.SearchResult.self} render={(location)}>
            <SearchResult route={pageRoutes.SearchResult} location={location}/>
          </Route>
          <Route path={pageRoutes.LeadSearch.self}> {/* add LeadSearch route */}
            <LeadSearch search_val={search_val}
                        route={pageRoutes.LeadSearch}
                        lead_type={lead_type}/> {/* assuming you want to pass the same props */}
          </Route>
          <Route path={pageRoutes.LeadView.self}> {/* add LeadSearch route */}
            <LeadView data={location}
                        route={pageRoutes.LeadView}
                        is_debtor={is_debtor}/> {/* assuming you want to pass the same props */}
          </Route>
          <Redirect to={{
            pathname: pageRoutes.SearchPage.self,
            // value: searchValue || defaultValue,
            search_val: search_val,
            search_field: search_field,
          }} />
        </Switch>
      </div>
    </div>
  );
};

export default SearchRouter;
