import React, { useState } from "react";

// React Router
import { Redirect, Route, Switch } from "react-router-dom";

// Pages
import AssignmentList from "../Shared/AssignmentList";
import Debtor from "../Shared/DebtorInformation";
import SecuredParty from "../Shared/SecuredPartyInformation";
import SecuredParties from "./SecuredParties";
import Debtors from "./Debtors";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentSecuredParty,
  updateCurrentDebtor,
  updateSpecificSecuredParty,
  updateSpecificDebtor,
  addADebtor,
  addASecuredParty,
  deleteDebtor,
  deleteSecuredParty,
} from "../../../redux/reducers/ucc1Slice";

const AssignmentUCC1 = ({ route }) => {
  // Redux State
  const { debtors, securedParties, currentSecuredParty, currentDebtor } =
    useSelector((state) => state.ucc1.currentData);
  const dispatch = useDispatch();

  // State
  const pageRoutes = {
    debtors: "/UCC1/Assignment/Debtors",
    securedParties: "/UCC1/Assignment/SecuredParties",
    list: "/UCC1/Assignment/List",
    viewDebtor: "/UCC1/Assignment/edit/Debtor",
    viewSecuredParty: "/UCC1/Assignment/edit/SecuredParty",
  };
  const [currentRoute, setCurrentRoute] = useState(
    debtors.length > 0 || securedParties.length > 0
      ? pageRoutes.list
      : pageRoutes.debtors
  );

  // Methods
  const nextToSecuredParty = () => {
    setCurrentRoute(pageRoutes.securedParties);
  };

  const nextToAssignmentList = () => {
    setCurrentRoute(pageRoutes.list);
  };
  const viewDebtorOrSecuredParty = (type, data, id) => {
    if (!type) console.log("Type error in viewDebtorOrSecuredParty: ", type);
    if (type === "Debtor") {
      dispatch(updateCurrentDebtor(id));
      setCurrentRoute(pageRoutes.viewDebtor);
    }
    if (type === "SecuredParty") {
      dispatch(updateCurrentSecuredParty(id));
      setCurrentRoute(pageRoutes.viewSecuredParty);
    }
  };
  const submitSecuredParty = (data) => {
    dispatch(updateSpecificSecuredParty(data));
    setCurrentRoute(pageRoutes.list);
  };

  const submitDebtor = (data) => {
    dispatch(updateSpecificDebtor(data));
    setCurrentRoute(pageRoutes.list);
  };

  return (
    <div className="FilingSystemPageLayout">
      <Switch>
        <Route path={pageRoutes.debtors}>
          <Debtors nextToSecuredParty={nextToSecuredParty} />
        </Route>

        <Route path={pageRoutes.securedParties}>
          <SecuredParties nextToAssignmentList={nextToAssignmentList} />
        </Route>
        <Route path={pageRoutes.list}>
          <AssignmentList
            route={route}
            view={viewDebtorOrSecuredParty}
            type="UCC1"
            deleteSecuredParty={deleteSecuredParty}
            deleteDebtor={deleteDebtor}
            addADebtor={addADebtor}
            addASecuredParty={addASecuredParty}
          />
        </Route>

        <Route path={pageRoutes.viewDebtor}>
          <Debtor
            file={debtors[currentDebtor]}
            submitDebtor={submitDebtor}
            UCC1={false}
            id={currentDebtor}
          />
        </Route>

        <Route path={pageRoutes.viewSecuredParty}>
          <SecuredParty
            file={securedParties[currentSecuredParty]}
            submitSecuredParty={submitSecuredParty}
            UCC1={false}
            id={currentSecuredParty}
          />
        </Route>
      </Switch>
      <Redirect to={currentRoute} />
    </div>
  );
};

export default AssignmentUCC1;
