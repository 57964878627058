import { Grid } from "@mui/material";
import React from "react";

const FilingCheckoutTable = ({ cart }) => {
  return (
    <Grid item xs={12} md={5}>
      <table className="Table">
        <tbody>
          <tr>
            <td>
              <b>Files</b>
            </td>
            <td>
              <b>Cost</b>
            </td>
          </tr>

          {/* Lists each item in the cart. */}
          {cart.map((item, index) => {
            return (
              <tr key={index}>
                <td>Item</td>
                <td>$35</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
};

export default FilingCheckoutTable;
