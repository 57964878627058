import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  user: null,
};

export const userSlice = createSlice({
  name: "User",
  initialState: { ...defaultState },
  reducers: {
    updateUser: (state, action) => {
      console.log('user', action.payload.user)
      let user = action.payload;
      let groups = user.signInUserSession.idToken.payload['cognito:groups'];
      const groupNames = ['lender_access', 'lead_access', 'paid_user', 'free_user'];

      groupNames.forEach(groupName => {
        user[groupName] = groups && (groups.includes('admin') || groups.includes(groupName));
      });
      state.user = user;
    },
    subtractSearch: (state) => {
      if (state.user && state.user.attributes && state.user.attributes['custom:free_searches']) {
        state.user.attributes['custom:free_searches']--;
      }
    },
  },
});

export const { updateUser,subtractSearch } = userSlice.actions;

export default userSlice.reducer;