import React, { useCallback, useEffect, useState } from "react";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import LabelAndSelect from "../../../Components/LabelAndSelect";
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import LabelAndCheckbox from "../../../Components/LabelAndCheckbox";
import { Grid } from "@mui/material";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateExtra,
  updateFileInCart,
  addFileToCart,
} from "../../../redux/reducers/ucc1Slice";

const ExtraInformation = ({ route }) => {
  // Redux State
  const currentData = useSelector((state) => state.ucc1.currentData);
  const extra = currentData.extra;
  const dispatch = useDispatch();

  // State
  const [alternateDesignation, setAlternateDesignation] = useState(
    extra.alternateDesignation || "None"
  );
  const [firstCheck, setFirstCheck] = useState(extra.firstCheck || false);
  const [secondCheck, setSecondCheck] = useState(extra.secondCheck || false);
  const [filerRefData, setFilerRefData] = useState(extra.filerRefData || "");

  // Methods
  const getDataTree = useCallback(() => {
    return {
      alternateDesignation: alternateDesignation,
      firstCheck: firstCheck,
      secondCheck: secondCheck,
      filerRefData: filerRefData,
    };
  }, [alternateDesignation, filerRefData, firstCheck, secondCheck]);

  const completeCurrentData = () => {
    dispatch(addFileToCart(currentData));
    dispatch(updateFileInCart(currentData));
  };

  // Events
  const onCompleteClicked = () => completeCurrentData();
  const onFilerRefDataChanged = (e) => setFilerRefData(e.target.value);
  useEffect(() => {
    dispatch(updateExtra(getDataTree()));
  }, [dispatch, getDataTree]);

  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center">
        {/* Extra Information Title */}
        <FilingSystemTitle title="Extra Info" />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container spacing={5} justifyContent="center">
            {/* Alternate Designation */}
            <Grid item xs={12} md={6}>
              <LabelAndSelect
                value={alternateDesignation}
                setValue={setAlternateDesignation}
                label="Alternate Designation"
                options={[
                  "None",
                  "AG Lien",
                  "Bailee / Bailor",
                  "Seller / Buyer",
                  "Non-Ucc Filing",
                  "Consignee / Consignor",
                  "Lessee / Lessor",
                ]}
              />
            </Grid>

            {/* Documentary Stamp Title */}
            <Grid item xs={12}>
              <div className="FilingSystemSubTitle">Documentary Stamp</div>
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <LabelAndCheckbox
                  value={firstCheck}
                  setValue={setFirstCheck}
                  label="All documentary stamps due and payable or to become due and payable pursuant to s. 201.22.F.S have been paid."
                />
                <LabelAndCheckbox
                  value={secondCheck}
                  setValue={setSecondCheck}
                  label="Florida Documentary Stamp Tax not required."
                />
              </Grid>
            </Grid>

            {/* Documentary Stamp Title */}
            <Grid item xs={12}>
              <div className="FilingSystemSubTitle">Filer Reference Data</div>
            </Grid>

            {/* Extra Text Area Input */}
            <Grid item xs={12} sm={9} md={4}>
              <textarea
                type="textArea"
                className="FilingSystemTextArea"
                value={filerRefData}
                onChange={onFilerRefDataChanged}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} md={4}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <Grid container justifyContent="center">
                <button className="FilingSystemButton">Upload Files</button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Link to={route.addAddendum}>
                <button className="FilingSystemButton">Add Addendum</button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link to={route.complete}>
                <button
                  className="FilingSystemButton"
                  onClick={onCompleteClicked}
                >
                  Complete
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExtraInformation;
