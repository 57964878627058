import React from "react";

// Components
import logo from "../Assets/logoI.png";
import { Link } from "react-router-dom";

// CSS
import "../css/Logo.css";

const Logo = ({ type }) => {
  return (
    <Link to="/">
      <img
        className="Logo"
        src={logo}
        alt="Logo"
      />

    </Link>
  );
};

export default Logo;
