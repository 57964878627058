import { configureStore } from "@reduxjs/toolkit";
import ucc1Reducer from "./reducers/ucc1Slice";
import ucc3Reducer from "./reducers/ucc3Slice";
import searchSlice from "./reducers/searchSlice";
import userSlice from "./reducers/userSlice";

export default configureStore({
  reducer: {
    ucc1: ucc1Reducer,
    ucc3: ucc3Reducer,
    searchSlice: searchSlice,
    userSlice: userSlice,
  },
});
