import React, { useEffect } from "react";

// Components
import FilingSystemTitle from "./FilingSystemTitle";
import { Grid } from "@mui/material";

// CSS
import "../../../css/FilingSystemShared.css";

// React Router
import history from "../../../history";
import { postOrderInDynamoDb } from "../../../api/dynamodb";
import { useDispatch } from "react-redux";

const ConfirmationPage = ({ order, resetState }) => {
  const dispatch = useDispatch();

  // Events
  useEffect(() => {
    postOrderInDynamoDb(order);
    return () => {
      if (history.action === "POP") {
        history.go(1);
      }
      dispatch(resetState());
    };
  }, [dispatch, order, resetState]);

  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center">
        {/* Confirmation Title */}
        <Grid item xs={11} md={12}>
          <FilingSystemTitle title="Order Confirmation" />
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6} md={1}>
              <Grid container justifyContent="center">
                <h2>Your order number is: {order?.orderNumber}</h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmationPage;
