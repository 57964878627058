import React from "react";
import Select, { components } from 'react-select';

const CustomControl = ({ children, ...props }) => {



    return (
        <originalComponents {...props}>
            <div
                style={{
                    display: 'flex',
                    background: '#22F69D',
                    borderRadius: '4px',
                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                    minHeight: "35px",
                    maxHeight: "35px",
                    // width: "235px",
                    width: "101%",
                    maxWidth: "200px",
                    paddingLeft: "1%",
                }}
                onClick={(e) => {
                    if (e.target === e.currentTarget.firstChild) {
                        props.selectProps.onControlClick();
                    }
                }}
            >
                <button style={{
                    border: 'none',
                    background: 'transparent',
                    color: 'white',
                    textTransform: "uppercase",
                    fontSize: "60%",
                    fontStyle: "normal",
                    fontWeight: "700",
                    letterSpacing: "0.46px",
                }}>
                    {props.selectProps.valueLabel}
                </button>

                {children}
            </div>
        </originalComponents>
    );
};

const EmptyComponent = () => null;

const SearchDropdown = ({ onChange, value, onControlClick }) => {
    const options = [
        { value: 'debtors', label: 'Debtors' },
        { value: 'secured_parties', label: 'Lenders' },
        { value: 'collateral', label: 'Collateral' },
        { value: 'file_num', label: 'File Number' },
        { value: 'main_search', label: 'All Fields' }
    ];

    const sort_text_options = {
        'debtors': 'Search Debtors',
        'secured_parties': 'Search Lenders',
        'collateral': 'Search Collateral',
        'file_num': 'Search File Number',
        'main_search': 'Search All Fields'
    };
    return (
        <Select
            className="SearchPage_SelectSearch"
            value={options.find(option => option.value === value)}
            options={options}
            isSearchable={false}
            onChange={onChange}
            components={{
                Control: CustomControl,
                SingleValue: EmptyComponent, // To hide the selected value text
                Placeholder: EmptyComponent // To hide the placeholder text
            }}
            onControlClick={onControlClick} // Custom prop for the left half click
            valueLabel={sort_text_options[value]} // Custom prop for button label
        />
    );
};

export default SearchDropdown;
