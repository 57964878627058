import React, { useState } from 'react';
import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";

const UccEventsTable = ({ uccEvents,filteredUccEvents,isPrev}) => {
    const [selectedFileNum, setSelectedFileNum] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showAllRows, setShowAllRows] = useState(false); // New state for showing all rows

    let amendments = uccEvents.filter(event => event.origFileNum === (filteredUccEvents[selectedRow] && filteredUccEvents[selectedRow].fileNumber) && filteredUccEvents[selectedRow] && filteredUccEvents[selectedRow].fileNumber != event.fileNumber);

    const handleClick = (fileNumber, index) => {
        setSelectedRow(index);
        setSelectedFileNum(selectedFileNum === fileNumber ? null : fileNumber);
    };

    let num_rows = isPrev ? 4 : 10; // Number of rows to show when showAllRows is false
    let tableHead = <tr>
            <th>File Number</th>
            <th>Original File Number</th>
            <th>Secured Party</th>
            <th>Filing Date</th>
            <th>Collateral</th>
            <th>Status</th>
            <th>Lapse Date</th>
            <th>Type</th>
        </tr>;
    let tableBody = (showAllRows ? filteredUccEvents : filteredUccEvents.slice(0, num_rows)).map((event, index) => (
        <React.Fragment key={index}>
            <tr onClick={() => handleClick(event.fileNumber, index)}
                className={selectedRow === index ? 'uccevents-selected-row' : ''}>
                <td>{event.fileNumber}</td>
                <td>{event.origFileNum}</td>
                <td>{event.securedParty}</td>
                <td>{event.filingDate}</td>
                <td>{event.collateral}</td>
                <td>{event.status}</td>
                <td>{event.lapse_date}</td>
                <td>{event.type}</td>
            </tr>
            {selectedFileNum === event.fileNumber && amendments.map((amendment, amendmentIndex) => (
                <tr className="uccevents-sub-row" key={`${index}-${amendmentIndex}`}>
                    <td>{amendment.fileNumber}</td>
                    <td>{amendment.origFileNum}</td>
                    <td>{amendment.securedParty}</td>
                    <td>{amendment.filingDate}</td>
                    <td>{amendment.collateral}</td>
                    <td>{amendment.status}</td>
                    <td>{amendment.lapse_date}</td>
                    <td>{amendment.type}</td>
                </tr>
            ))}
        </React.Fragment>
    ));
    if (isPrev) {
        tableHead = <tr>
            <th>File Number</th>
            <th>Collateral</th>
            <th>Status</th>
            <th>Lapse Date</th>
        </tr>;
        tableBody = (showAllRows ? filteredUccEvents : filteredUccEvents.slice(0, num_rows)).map((event, index) => (
            <React.Fragment key={index}>
                <tr onClick={() => handleClick(event.fileNumber, index)}
                    className={selectedRow === index ? 'uccevents-selected-row' : ''}>
                    <td>{event.fileNumber}</td>
                    <td>{event.collateral}</td>
                    <td>{event.status}</td>
                    <td>{event.lapse_date}</td>
                </tr>
                {selectedFileNum === event.fileNumber && amendments.map((amendment, amendmentIndex) => (
                    <tr className="uccevents-sub-row" key={`${index}-${amendmentIndex}`}>
                        <td>{amendment.fileNumber}</td>
                        <td>{amendment.collateral}</td>
                        <td>{amendment.status}</td>
                        <td>{amendment.lapse_date}</td>
                    </tr>
                ))}
            </React.Fragment>
        ));
    }

    let dropdown = (filteredUccEvents.length > num_rows) ? <div className="dropdown-container">
            <button
                className="dropdown-button"
                onClick={() => setShowAllRows(!showAllRows)}
            >
                {showAllRows ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
        </div> :<></>

    return (filteredUccEvents.length > 0) ? <div className="ucc-events">
            <h2 className="ucc-events-heading">UCC Events</h2>
            <table className="ucc-events-table">
                <thead>
                {tableHead}
                </thead>
                <tbody>
                {tableBody}
                </tbody>
            </table>
            {dropdown}
            <p>** Note not all UCC events are present due to large search size. Please supplement with full UCC search if necessary.</p>
    </div> : <></>;
};

export default UccEventsTable;
