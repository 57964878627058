// Pages
import ContactInformation from "../Shared/ContactInformation";
import MiscAmendment from "./MiscAmendment";
import Authorization from "./Authorization";
import AssignmentUCC3 from "./AssignmentUCC3";
import ConfirmationPage from "../Shared/ConfirmationPage";
import FindUCC3 from "./FindUCC3";

// Components
import Header from "../../../Components/Header";

// React Router
import { Route, Switch, Redirect } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addCurrentDataToCart,
  resetState,
} from "../../../redux/reducers/ucc3Slice";

// CSS
import "../../../css/FilingSystemShared.css";
import CheckoutUCC3Container from "./CheckoutUCC3Container";

const UCC3 = () => {
  // Redux State
  const file = useSelector((state) => state.ucc3.currentData.fileFound);
  const order = useSelector((state) => state.ucc3.order);

  const dispatch = useDispatch();

  // State
  // List of pages in UCC3.
  const pageRoutes = {
    FindUCC3: {
      self: "/UCC3/FindUCC3",
      next: "/UCC3/ContactInformation",
    },
    ContactInformation: {
      self: "/UCC3/ContactInformation",
      next: "/UCC3/Assignment",
    },
    Assignment: {
      self: "/UCC3/Assignment",
      next: "/UCC3/MiscAmendment",
    },
    MiscAmendment: {
      self: "/UCC3/MiscAmendment",
      next: "/UCC3/Authorization",
    },
    Authorization: {
      self: "/UCC3/Authorization",
      complete: "/UCC3/Checkout",
    },
    Checkout: {
      self: "/UCC3/Checkout",
      complete: "/UCC3/Confirmation",
      addAFiling: "/UCC3/FindUCC3",
    },
    Confirmation: {
      self: "/UCC3/Confirmation",
    },
  };

  // Methods
  const addToCart = () => dispatch(addCurrentDataToCart());

  return (
    <div>
      {/* Logo */}
      <Header />

      {/* Routes */}
      <div className="FilingSystemPageLayout">
        <Switch>
          <Route path={pageRoutes.FindUCC3.self}>
            <FindUCC3 route={pageRoutes.FindUCC3} />
          </Route>
          <Route path={pageRoutes.ContactInformation.self}>
            <ContactInformation route={pageRoutes.ContactInformation} />
          </Route>
          <Route path={pageRoutes.Assignment.self}>
            <AssignmentUCC3 route={pageRoutes.Assignment} />
          </Route>
          <Route path={pageRoutes.MiscAmendment.self}>
            <MiscAmendment route={pageRoutes.MiscAmendment} />
          </Route>
          <Route path={pageRoutes.Authorization.self}>
            <Authorization
              route={pageRoutes.Authorization}
              addToCart={addToCart}
            />
          </Route>
          <Route path={pageRoutes.Checkout.self}>
            <CheckoutUCC3Container route={pageRoutes.Checkout} />
          </Route>
          <Route path={pageRoutes.Confirmation.self}>
            <ConfirmationPage order={order} resetState={resetState} />
          </Route>
          <Redirect to={pageRoutes.FindUCC3.self} />
        </Switch>
        {order ? (
          <Redirect to={pageRoutes.Confirmation.self} />
        ) : (
          !file && <Redirect to={pageRoutes.FindUCC3.self} />
        )}
      </div>
    </div>
  );
};

export default UCC3;
