import React from "react";
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { updateUser } from '../redux/reducers/userSlice';
// Components
import { Link } from "react-router-dom";
import Logo from "../Components/Logo";

const Header = ({user}) => {



    const signOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };
    user = user.user
    let num_searches = user.attributes['custom:free_searches']
    console.log(user)
    let lead_link = user.lead_access ? <Link
        className="HeaderUrl"
        to={{
            pathname: "/SearchRouter/LeadSearch",
            value: '',
            search: '',
            lead_type: 'debtor',
        }}
    >Lead Search</Link> : <> </>;
    let lender_link = user.lender_access ? <Link
        className="HeaderUrl"
        to={{
            pathname: "/SearchRouter/LeadSearch",
            value: '',
            search: '',
            lead_type: 'sp',
        }} >Lender Search</Link> : <> </>;
    return (
      <div className="Header">
          <div className="HeaderLinks">
              <Logo type="LogoM" />
              <Link to='/HowItWorksPage' className="HeaderUrl">How It Works</Link>
              <Link to="/AboutUs" className="HeaderUrl">About Us</Link>
              {/*<Link to="/FilingSystem" className="HeaderUrl">File With Us</Link>*/}
            {lead_link}
            {lender_link}
          </div>
          <div className="Header_User">
              <h2 className="Header_User_Heading">Hello {user.username}</h2>
              <hr></hr>
              {!user.paid_user && <p className="Header_User_FreeSearches">{num_searches} Free Searches</p>}
              <hr></hr>
              <button className="Header_User_SignOutBtn" onClick={signOut}>Sign out</button>
          </div>

      </div>
  );
};

const mapStateToProps = (state) => ({
    user: state.userSlice,
});

export default connect(mapStateToProps)(Header);
