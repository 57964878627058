import React from "react";

// Components
import { Grid } from "@mui/material";

// CSS
import "../../../css/FilingSystemShared.css";

const FilingSystemTitle = ({ title, subTitle }) => {
  return (
    <Grid item xs={11} md={12}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12}>
          <Grid container justifyContent="center" gap={1}>
            <div className="FilingSystemTitle">{title}</div>
            {subTitle && <div>{subTitle}</div>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilingSystemTitle;
