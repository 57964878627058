import React from "react";
import Header from "../Components/Header";

// CSS
import "react-awesome-button/dist/styles.css";
import "../css/HowItWorks.css";

const HowItWorksPage = () => {

  return (
      <div className="HowItWorks">
        {/* Header */}
        <Header />
        <div className="HWContent">
            <p>
                At PitaTech, we make it easy to access UCC filing data from the Missouri Secretary of State. We use
                advanced machine learning to extract critical collateral information from the filings, and then index it
                in our searchable database. This means that you can quickly and easily find the data you need by searching
                for a Debtor, Secured Party, or Collateral.
            </p>
            <p>
                PitaTech is a powerful search engine that helps you find debtors, secured parties and collateral quickly
                and easily. To get started, simply type in the Debtor, Secured Party or Collateral you are looking for
                and hit the Search button. The results will be displayed and you can scroll through them to find the
                one you are looking for.
            </p>
            <p>
                Once you click on a result, you will be able to view more details about it including ammendments, filing
                documents, filing dates, and expanded collateral details. You can also use the filter option to apply a
                more precise search. Simply specify the additional information you are looking for and the filter will
                be applied to your original search. You can also uncheck the "Match Exact Search" box to apply a more
                general search.
            </p>
            <p>
                With PitaTech, finding debtors, secured parties and collateral is quick and easy!
            </p>
            <h2>UCC Search</h2>
            <iframe width="600" height="600" src="https://www.youtube.com/embed/ybE-t-dcBe0"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
            </iframe>
            <h2>Lead Search</h2>
            <iframe width="600" height="600" src="https://www.youtube.com/embed/AYoir3QjOzQ"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>

            </iframe>
        </div>

      </div>
  );
};

export default HowItWorksPage;
