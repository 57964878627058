import React from "react";

// Components
import FilingSystemTitle from "./FilingSystemTitle";
import AssignmentDAndSPContainer from "../../../Components/AssignmentDAndSPContainer";
import { Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";

const AssignmentListOfItems = ({
  view,
  route,
  nextPage,
  deleteSecuredParty,
  deleteDebtor,
  addADebtor,
  addASecuredParty,
  type,
}) => {
  // Redux State
  const { debtors, securedParties } = useSelector((state) =>
    type === "UCC1" ? state.ucc1.currentData : state.ucc3.currentData
  );
  const dispatch = useDispatch();

  //Methods
  const addDebtor = () => dispatch(addADebtor({}));
  const addSecuredParty = () => dispatch(addASecuredParty({}));

  // Events
  const onAddADebtorClicked = () => addDebtor();
  const onAddASecuredPartyClicked = () => addSecuredParty();

  // Build JSX
  const buildDebtorsAndSecuredParties = (array, type) => {
    return array?.map((item, id) => {
      return (
        <AssignmentDAndSPContainer
          key={uuidv4()}
          title={`${type === "Debtor" ? "Debtor" : "Secured Party"}: `}
          data={item}
          deleteDebtorOrSecuredParty={() =>
            dispatch(
              type === "Debtor" ? deleteDebtor(id) : deleteSecuredParty(id)
            )
          }
          view={() => view(type, item, id)}
        />
      );
    });
  };

  return (
    <Grid container spacing={5} justifyContent="center" alignItems="center">
      {/* Title */}
      <FilingSystemTitle title="Assignment" />

      {/* Body */}
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={9} md={4}>
            <Grid container spacing={1} justifyContent="center">
              {buildDebtorsAndSecuredParties(debtors, "Debtor")}
              {/* Add a Debtor Button */}
              <Grid item xs={4}>
                <button
                  className="FilingSystemButton"
                  onClick={onAddADebtorClicked}
                >
                  Add Debtor
                </button>
              </Grid>

              {buildDebtorsAndSecuredParties(securedParties, "SecuredParty")}
              {/* Add a Secured Parties Button */}
              <Grid item xs={4}>
                <button
                  className="FilingSystemButton"
                  onClick={onAddASecuredPartyClicked}
                >
                  Add Secured Party
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Grid item xs={11} sm={9} md={4}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={6}>
            <Link to={route.next}>
              <button className="FilingSystemButton" onClick={nextPage}>
                Next
              </button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignmentListOfItems;
