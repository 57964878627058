import React, { useCallback, useEffect, useState } from "react";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import LabelAndInput from "../../../Components/LabelAndInput";
import FilingSystemTitle from "./FilingSystemTitle";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateContactInformationState } from "../../../redux/reducers/ucc3Slice";
import { updateContactInformation } from "../../../redux/reducers/ucc1Slice";

const ContactInformation = ({ route, isUCC1 }) => {
  // Redux State
  const contactInformationState = useSelector((state) => {
    if (isUCC1) {
      return state.ucc1.currentData.contactInformation;
    } else {
      return state.ucc3.currentData.contactInformation;
    }
  });
  const dispatch = useDispatch();

  // State
  const [name, setName] = useState(contactInformationState?.name || "");
  const [phone, setPhone] = useState(contactInformationState?.phone || "");
  const [email, setEmail] = useState(contactInformationState?.email || "");

  // Methods
  const getDataTree = useCallback(() => {
    return {
      name: name,
      phone: phone,
      email: email,
    };
  }, [email, name, phone]);

  // Events
  useEffect(() => {
    if (isUCC1) dispatch(updateContactInformation(getDataTree()));
    else dispatch(updateContactInformationState(getDataTree()));
  }, [dispatch, getDataTree, isUCC1]);

  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center">
        {/* Title */}
        <FilingSystemTitle title="Contact Information" />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container spacing={3} justifyContent="center">
            {/* Name Input */}
            <LabelAndInput
              setValue={setName}
              value={name}
              label="Name"
              type="text"
            ></LabelAndInput>

            {/* Phone Input */}
            <LabelAndInput
              setValue={setPhone}
              value={phone}
              label="Phone"
              type="text"
            ></LabelAndInput>

            {/* Email Input */}
            <LabelAndInput
              setValue={setEmail}
              value={email}
              label="Email"
              type="email"
            ></LabelAndInput>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} sm={9} md={4}>
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid item xs={6}>
              <Link to={route.next}>
                <button className="FilingSystemButton">Next</button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactInformation;
