import { uccFLAPI,expFLAPI } from "./axios";
import { performAPIErrorChecking, performAPIResultsFound } from "./APIServices";
import { Auth } from 'aws-amplify';

// Contains the axios getter to UCCs file path.
const UCCs = (params, headers) => {
  return uccFLAPI.get("/V0/", {
    params: params,
    headers: headers,
    timeout: 900000,
  });
};
// Searches FL API By File Number.
const performFileNumSearch = (searchValue) => {
  const params = {
    main_search:'',
    debtors:'',
    secured_parties:'',
    collateral:'',
    file_num:searchValue.toString(),
    page_num:'',
    exact:'',
    lead:'',
    sortBy: '',
    sortOrd: '',
  };
  // const params = { file_num: searchValue.toString() };
  const type = "Perform UCC FL API File Number Search";

  // Retrieves data from API using the "params" as guidance.
  return UCCs(params)
    .then((res) => performAPIResultsFound(res, type))
    .catch((res) => performAPIErrorChecking(res, type));
};

// Searches FL API By q parameter.
const performSearch = async(searchDct,pg,exBool) => {

  let onlyEmp = searchDct['onlyEmp'] != '' ? searchDct['onlyEmp'] : 'true'
  let lead_start = searchDct['lead_start'] ? searchDct['lead_start'].toString() : ''
  let lead_end = searchDct['lead_end'] ? searchDct['lead_end'].toString() : ''
  let lead_type = searchDct['lead_type'] ? searchDct['lead_type'] : ''
  const params = {
    main_search:searchDct['main_search'],
    debtors:searchDct['debtors'],
    secured_parties:searchDct['secured_parties'],
    collateral:searchDct['collateral'],
    file_num:searchDct['file_num'],
    lead:searchDct['lead'],
    onlyEmp: onlyEmp,
    lead_start:lead_start,
    lead_end:lead_end,
    lead_type:lead_type,
    page_num:pg,
    exact:exBool,
    sortBy: searchDct['sort'][0],
    sortOrd: searchDct['sort'][1],
  };
  const type = "Perform UCC FL API Search";
  const session = await Auth.currentSession();
  const headers = {
    Authorization: `${session.idToken.jwtToken}`
  };


  // Retrieves UCCs from API using the "params" as guidance.
  return UCCs(params, headers)
      .then((res) => performAPIResultsFound(res, type))
      .catch((res) => performAPIErrorChecking(res, type));

};

const exportSearch = async(searchDct,pg,exBool) => {

  let exclude = searchDct['exclude'] ? searchDct['exclude'].join(','): ''
  let onlyEmp = searchDct['onlyEmp'] != '' ? searchDct['onlyEmp'] : 'true'
  let lead_type = searchDct['lead_type'] ? searchDct['lead_type'] : ''
  const params = {
    main_search:searchDct['main_search'],
    debtors:searchDct['debtors'],
    secured_parties:searchDct['secured_parties'],
    collateral:searchDct['collateral'],
    file_num:searchDct['file_num'],
    page_num:pg,
    exact:exBool,
    sortBy: searchDct['sort'][0],
    sortOrd: searchDct['sort'][1],
    search_size: 1000,
    exclude: exclude,
    lead:searchDct['lead'],
    onlyEmp: onlyEmp,
    lead_type:lead_type,
  };
  const type = "Perform UCC Export ";

  const session = await Auth.currentSession();
  const headers = {
    Authorization: `${session.idToken.jwtToken}`
  };
  console.log('params',params)
  return expFLAPI.get("/", {
    params: params},
  );

}

export { performFileNumSearch, performSearch,exportSearch };
