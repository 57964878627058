import Routes from "./Routes.js";
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {connect} from "react-redux";
import React, {useEffect, useState} from 'react';
import {updateUser} from "./redux/reducers/userSlice";
import { Auth,Analytics } from 'aws-amplify';
import LandingPage from './Pages/LandingPage';


const App = (props) => {

    function log_user(user){
        Analytics.updateEndpoint({
            address: user.attributes.email, // for example
            channelType: 'EMAIL',
            optOut: 'NONE',
            userId: user.username,
            userAttributes: {
                free_user:user.free_user,
                paid_user:user.paid_user,
                lender_access:user.lender_access,
                lead_access:user.lead_access,
                free_searches:user.attributes["custom:free_searches"],
            }
        });
    }
    useEffect(() => {
        if (localStorage.getItem('refreshUserOnLoad') === 'true') {
            const fetchUser = async () => {
                try {
                    const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
                    props.dispatch(updateUser(data.user));
                } catch (error) {
                    console.log('error fetching user', error);
                }
            };

            fetchUser();
            localStorage.setItem('refreshUserOnLoad', 'false');  // reset the flag
        }
    }, []);  // Empty dependency array to run once on mount

    window.onbeforeunload = function() {
        localStorage.setItem('refreshUserOnLoad', 'true');
    };

    if (props.user && props.user.username) {
        props.dispatch(updateUser(props.user));
    }
  return (
      <div>
          <Routes />
      </div>
  );
};

function mapStateToProps(state,props) {
    return props
}

export default connect(mapStateToProps)(App);


