import React, {useEffect, useState} from "react";
import { FaCheckSquare, FaSquare, FaEye, FaDownload } from 'react-icons/fa';
import { BsSquare, BsCheckSquare } from "react-icons/bs";


// CSS
import "../../css/SearchResult.css";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {updateSearchState} from "../../redux/reducers/searchSlice";

const SearchPreview = (props) => {
    // {fileNum,fileDate,expDate,collateral,debtors,sp,img_url,status,statusColor,ammendments}
    let [fileNum, setfileNum] = useState('');
    let [fileDate, setfileDate] = useState('');
    let [expDate, setexpDate] = useState('');
    let [adj_lapse_date, setadjLapseDate] = useState('');
    let [collateral, setcollateral] = useState('');
    let [debtors, setdebtors] = useState('');
    let [sp, setsp] = useState('');
    let [img_url, set_img_url] = useState('');
    let [status, setstatus] = useState('');
    let [statusColor, setstatusColor] = useState('');
    let [ammendments, setammendments] = useState('');
    let [type, setType] = useState('');
    let [collatHeading, setCollatHeading] = useState('Collateral');
    const [isChecked, setIsChecked] = useState(true);

    let excludeCallback = props.excludeCallback;


    useEffect(() => {
        if (props.location.state && props.location.state.previewData) {
            extract_params(props.location.state.previewData);
        }
    },[img_url,isChecked,props.location.state.previewData]);

    const handleCheckboxChange = (event) => {
        setIsChecked(!isChecked);
        if (isChecked) {
            excludeCallback(fileNum, true);
            console.log('excludeCallback',true)
        } else {
            excludeCallback(fileNum, false);
            console.log('excludeCallback',false)
        }
    };

    function clean_url(url){
        url = (url !== null && url !== '' && url) ? "https://pitamodata-pub.s3.amazonaws.com/"+url : '';
        url = url.replace('tif','pdf');
        // Check if the URL ends with '.pdf', if not, add '.pdf'
        if (!url.endsWith('.pdf')) {
            url += '.pdf';
        }
        return url;
    }

    function onAmmendClick(amend){
        // Extract the new img_url from the amend object
        let newImgUrl = amend.img_url;

        // Call the updateImageUrl function received from props
        if (!isMain){
            props.updateImageUrl(newImgUrl);
            newImgUrl = clean_url(newImgUrl);
            set_img_url(newImgUrl);
        }
    }

    function buildPreviewAmendments(amendments,curr_filing){
        if (amendments.length === 0){
            return<></>
        }
        amendments = [curr_filing, ...amendments];
        amendments.sort((a, b) => moment(a.file_date, 'YYYY-MM-DD').diff(moment(b.file_date, 'YYYY-MM-DD')))
        const amendList = amendments.map((amend) => {
            let file_date = moment(amend.file_date.split(' ')[0], "YYYY-MM-DD").format('ll');
            let ammend_type = amend['type'].replace('UCC3 - ','').replace('UCC1 - ','').replace('Amendment (','').replace(')','');
            return <div className="SearchResult_ResultPreviewAmendLst" onClick={() => onAmmendClick(amend)}>
                <p className='SearchResult_ResultPreviewAmendContent'>{file_date}</p>
                <p className='SearchResult_ResultPreviewAmendContent' style={{textDecorationLine:"underline", color:"#0064FA"}}>{amend.file_num}</p>
                <p className='SearchResult_ResultPreviewAmendContent'>{ammend_type}</p>
            </div>;
        });
        return <div className={'SearchPage_ResultPreviewAmendSection'}>
            <hr style={{color:"rgba(0, 0, 0, 0.25)", marginLeft:10, marginRight:10}}/>
            <div className="SearchPage_ResultPreviewAmend">
                <h3 className='SearchPage_ResultPreviewAmendTitle'>UCC Events</h3>
                {}
                {amendList}
            </div>
        </div>
    }
    // fileDate,lapse_date, status, ammendments
    function extract_params(data) {
        const fileNum = data.file_num;
        const fileDate = data.file_date.includes(' ') ?
            moment(data.file_date.split(' ')[0], "YYYY-MM-DD").format('ll') :
            moment(data.file_date, "YYYY-MM-DD").format('ll');
        const expDate = data.lapse_date ? moment(data.lapse_date.split(' ')[0], "YYYY-MM-DD").format('ll') : null;
        const adj_lapse_date = data.adj_lapse_date ? moment(data.adj_lapse_date.split(' ')[0], "YYYY-MM-DD").format('ll') : null;
        let collateral;
        let collatHeading = "Collateral";
        if ('collateral' in data) {
            collateral = data.collateral;
        } else {
            collatHeading = "Initial Filing Collateral";
        }
        let debtors;
        if (data.debtors) {
            debtors = data.debtors;
            debtors = debtors.map((debtor) => {return (debtor.org_name !== "") ? debtor.org_name : debtor.full_name}).join(', ');
            debtors = debtors.split(', ').filter((v, i, a) => a.indexOf(v) === i).join(', ');
        }
        let sp;
        if (data.secured_parties) {
            sp = data.secured_parties;
            sp = sp.map((s) => { return (s.org_name !== "") ? s.org_name : s.full_name }).join(', ');
            sp = sp.split(', ').filter((v, i, a) => a.indexOf(v) === i).join(', ');
        }
        let url = clean_url(data.img_url);
        const status = data.status ? data.status : "Unknown";

        const statusColor = status === "Active" ? "#53ECABFF" : "#b7b7b7";
        const ammendments = data.ammendments ? buildPreviewAmendments(data.ammendments, data) : null;
        const type = (data.type === 'Initial') ? "UCC1 - Original" : data.type;

        let exclude = props.location.state.exclude;
        setfileNum(fileNum);
        setfileDate(fileDate);
        setexpDate(expDate);
        setadjLapseDate(adj_lapse_date);
        setcollateral(collateral);
        setCollatHeading(collatHeading);
        setdebtors(debtors);
        setsp(sp);
        set_img_url(url);
        setstatus(status);
        setstatusColor(statusColor);
        setammendments(ammendments);
        setType(type);
        setIsChecked(!exclude.includes(fileNum));
    }

    function clean_word(word){
        return word.replace(/[^a-zA-Z ]/g, "");
    }
    function titleCase(str) {
        if (!str) return ''; // Add this line to handle null or undefined values
        return str.split(' ').map(item =>
            (clean_word(item).length > 3) ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item
        ).join(' ');
    }

    function build_prev_urls(isMain,state,page_preview_details,pdf_url,dispatch){
        let preview_urls;
        if (isMain){
            if (img_url !== null && img_url !== '') {
                preview_urls =
                    <div className="SearchResult_ResultPreviewDetailsLst">
                        <Link to={{
                            pathname: state.route.next,
                            page_preview_details: {page_preview_details}
                        }}
                              onClick={() => {
                                  dispatch(updateSearchState(state))
                              }}
                              className="SearchPage_ResultPreviewDetails">
                            <FaEye size={26}/>
                        </Link>
                        <a href={img_url} className="SearchPage_ResultPreviewDownload"><FaDownload size={26}/></a>
                    </div>
            }else{
                preview_urls =
                    <h3 style={{textAlign:"center", fontWeight:"normal"}}>
                        No Image Available.
                    </h3>
            }
        }else{
            preview_urls =
                <div className="SearchResult_ResultPreviewDetailsLst">
                    <a className='SearchPage_ResultPreviewDownload' href={img_url}><FaDownload size={24}/></a>
                </div>
        }
        return preview_urls
    }

    const page_preview_details = props.location;
    let {
        isMain,
        state,
        dispatch,
        pdf_url,
    } = page_preview_details;
    // setfileNum(fileNum);
    // const pdf_url = 'https://pitamodata-pub.s3.amazonaws.com/Final_Images/20200615000002
    let preview_urls = build_prev_urls(isMain,state,page_preview_details,pdf_url,dispatch);
    let date_range = adj_lapse_date ? fileDate + " - " + adj_lapse_date : fileDate
    let prev_width = isMain ? "60%" : "100%";
    return (
        <div className="SearchResult_ResultPreview" style={{width:prev_width}}>
            <div className="SearchResult_ResultPreviewHead">
                <div className="SearchResult_ResultPreviewHeadLeft">
                    {isChecked ? (
                        <BsCheckSquare className="SearchResult_PreviewHeadCheckbox" fill="#53ECABFF" size={35} onClick={handleCheckboxChange} />
                    ) : (
                        <BsSquare className="SearchResult_PreviewHeadCheckbox" fill="#53ECABFF" size={35} onClick={handleCheckboxChange} />
                    )}
                    <div className="SearchResult_ResultPreviewHeadFileMain">
                        <h2 className="SearchResult_PreviewHeadFn">{fileNum}</h2>
                        <div className="SearchResult_PreviewHeadSub">
                            <p className="SearchResult_PreviewHeadState"><b>Missouri</b> - {type} </p>
                        </div>
                        <p className="SearchResult_ResultPreviewDateText">{date_range}</p>
                    </div>
                </div>
                <div className="SearchResult_ResultPreviewStatus">
                    <div  className="SearchResult_ResultPreviewStatusText" style={{color:statusColor, borderColor:statusColor}}><p >{status}</p></div>
                    {preview_urls}
                </div>
            </div>
            <hr style={{color:"rgba(0, 0, 0, 0.09)", marginLeft:10, marginRight:10,marginTop:-5}}/>
            <div className="SearchResult_ResultPreviewParties">
                <div className="SearchResult_ResultPreviewDebtors">
                    <h3 className='SearchResult_ResultPreviewDebtorTitle'>Debtors</h3>
                    <p className='SearchResult_ResultPreviewDebtorLst'>{titleCase(debtors)}</p>
                </div>
                <div className="SearchResult_ResultPreviewSP">
                    <h3 className='SearchResult_ResultPreviewSPTitle'>Secured Parties</h3>
                    <p className='SearchResult_ResultPreviewSPLst'>{titleCase(sp)}</p>
                </div>
            </div>
            <hr style={{color:"rgba(0, 0, 0, 0.09)", marginLeft:10, marginRight:10}}/>
            <div className="SearchResult_ResultPreviewCollat">
                <h3 className='SearchResult_ResultPreviewCollatTitle'>{collatHeading}</h3>
                <p className='SearchResult_ResultPreviewCollatText'>{collateral}</p>
            </div>
            {ammendments}
        </div>
  );
};

export default SearchPreview;
