import React from "react";
import Header from "../Components/Header";

// CSS
import "react-awesome-button/dist/styles.css";
import "../css/AboutUs.css";

const AboutUs = () => {

  return (
      <div className="AboutUs">
        {/* Header */}
          <Header />
          <div className="ABContent">
              <p>Welcome to PitaTech!</p>
              <p>We are a venture-backed startup out of Columbia, Missouri, backed by both Scale and The Legal Tech Fund.
                  Our mission is to help solve the many pain points associated with commercial equipment processing by
                  making the UCC diligence process easier.</p>
              <p>Our founder, Caleb Heinzman, left his PhD in Machine Learning from Mizzou to create the company, after
                  learning about first hand problems in equipment financing and UCC filings that Willy and Jabbock
                  Schlack's experience aat equipmentshare.</p>
              <p>At PitaTech, we are passionate about helping our clients save time and money. Our software solutions
                  are designed to streamline and simplify the UCC filing process, allowing businesses to focus on what
                  matters most: their products and services.</p>
              <p>We are proud to be part of the Columbia, Missouri startup scene and are committed to helping our
                  clients succeed.</p>
              <p>If you have any questions or comments, please do not hesitate to contact us. We look forward to
                  hearing from you!</p>
          </div>

      </div>
  );
};

export default AboutUs;
