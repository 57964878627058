import React from "react";
import { IoIosArrowBack } from 'react-icons/io';

// API
import { performSearch,exportSearch } from "../../../api/searchFLAPI";

// Components
import LoadingSpinner from "../../../Components/LoadingSpinner";
import Logo from "../../../Components/Logo";
import { v4 as uuidv4 } from "uuid";
import Pagination from '@mui/material/Pagination';
import Select from 'react-select'
import LeadView from './LeadView';

// CSS
import "../../../css/LeadSearch.css";
class LeadSearch extends React.Component {
  // State
  constructor(props) {
    super(props);
    let numResults = 20;
    const  search_val  = props.search_val;
    const  route  = props.route;
    const lead_type = props.lead_type;
    const is_debtor = lead_type != 'sp' ? true : false
    this.state = {
      lead:'',
      exactSearch: true,
      search_val: search_val,
      route: route,
      date_val: '',
      postId: null,
      loading: true,
      loadingType:'presearch',
      noResults: false,
      error: null,
      data: [],
      pg: 1,
      total_pages: 0,
      numResults:numResults,
      allResults: [],
      previewStatus: false,
      previewData: null,
      previewIdx: -1,
      resultBg: {},
      sort: ["status",'asc'],
      main_search_field: "debtors",
      leadViewOpen: false,
      leadData: null,
      onlyEmp: 'true',
      is_debtor: is_debtor,
      lead_type: lead_type,
    };
    this.dispatch = props.dispatch;
    this.scrollRefs = Array.from(Array(numResults)).map((_) => React.createRef())

    this.switchPreviewState = this.switchPreviewState.bind(this)
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this)
    this.onExactSearchChange = this.onExactSearchChange.bind(this)
    this.tabFunction = this.tabFunction.bind(this)
    this.onShowAllChange = this.onShowAllChange.bind(this);

  }

  onShowAllChange() {
    this.setState({
      onlyEmp: this.state.onlyEmp == 'true' ? 'false' : 'true'
    });
  }

  tabFunction(event) {
    if (event.key === "Tab" && !this.state.loading) {
      event.preventDefault();

      let idx = this.state.previewIdx + 1;
      let lastIdx = this.state.pg *this.state.numResults;
      let keepPage = idx >= 0 && idx < lastIdx && idx < this.state.data.length;

      if (keepPage) {
        this.setState({previewIdx: idx}, () => {
          this.switchPreviewState(this.state.data[idx], idx);
        });
      } else {
        // If we're at the end of the page, go to the next one
        this.handleChangePage(this.state.pg + 1,idx);
      }
    }
  }


  async runSearchRequest({page=1,sort=null}= {}){
    this.resetPage(page);
    let lead_start = (page-1)*this.state.numResults;
    let lead_end = lead_start+ this.state.numResults-1;
    let search_dct = {
      'main_search':'',
      'debtors':'',
      'secured_parties':'',
      'collateral':'',
      'file_num':'',
      'lead':this.state.search_val,
      'onlyEmp':this.state.onlyEmp,
      'lead_start':lead_start,
      'lead_end':lead_end,
      'lead_type':this.state.lead_type,
      '_id':'',
      'hist':'',
      'date':'',
      'sort':(sort !== null) ? sort : this.state.sort,
    }
    console.log("SEARCH DCT",search_dct)
    const result = await performSearch(search_dct,page-1,this.state.exactSearch);
    console.log(result)
    let data = result.data;
    let total_pgs = data[0].total_pgs;
    data = data.slice(1);
    const allResults = this.getSearchResults(data);
    if (data) {
      if (data.length > 0)
        this.scrollRefs = Array.from(Array(data.length)).map((_) => React.createRef());
        this.setState({data: data,allResults:allResults, loading: false, noResults: false,total_pages:total_pgs});
      if (data.length <= 0)
        this.setState({data: data,allResults:allResults, loading: false, noResults: true });
    }

    console.log("RESULTS",allResults)
    if (result.error)
      this.setState({
        data: data,
        loading: false,
        noResults: false,
        error: true,
        allResults:allResults
      });
  }

  async componentDidMount() {
    // Retrieves data from API using the "params" as guidance.
    console.log("MOUNTED COMPONENT")
    if (this.state.search_val !== ''){
      await this.runSearchRequest();
    }
  }

  getSearchResults(data) {
    if (!data) return;
    return data.map((item,idx) => {
      return this.buildItemBox(item, uuidv4(),idx);
    });
  }
  executeScroll = (idx) => {
    if (this.scrollRefs[idx] && this.scrollRefs[idx].current) {
      this.scrollRefs[idx].current.scrollIntoView();
    }
  }

  handleLeadViewOpen = (data) => {
    this.setState({
      leadViewOpen: true,
      leadData: data,
      previewStatus: false,
    });
  }
  handleResultClick = (data) => {
    this.setState({
      previewStatus: true,
      leadData:data
    });
  }


  buildItemBox(data, key,idx) {
    let tempDct = this.state.resultBg;
    tempDct[idx] = tempDct[idx] !== undefined ? tempDct[idx] : "white";
                              this.setState({resultBg : tempDct});

    let party_key = (data.key);
    let empLength = (data.employee) ? data.employee.length : 0;
    let p_src = data.top_docs.hits.hits[0]._source;
    let p = (this.state.is_debtor) ? p_src.debtors[0] : p_src.secured_parties[0];
    const address = p.addr1 + ', ' + p.city + ' ' + p.state;

    return (
        {'body':
          <div ref={this.scrollRefs[idx]} className="LeadSearch_SingleResult" style={{backgroundColor:this.state.resultBg[idx]}}>
            <div
                ref={this.scrollRefs[idx]}
                className="LeadSearch_SingleResult"
                style={{backgroundColor:this.state.resultBg[idx]}}
                onClick={() => this.switchPreviewState(data,idx)}
            >
              <div className="LeadSearch_ResultHead">
                <h3 className="LeadSearch_ResultSP">{party_key} </h3>
              </div>
              <p className="LeadSearch_ResultCollat" style={data.collat_style}>{address}</p>
              <p className="LeadSearch_ResultAmend">{empLength} Employees Found</p>
            </div>
          </div>,
    }
    );
  }

  resetPage(page){
    let tempDct = this.state.resultBg;
    let results = this.state.data.map((item, i) => {
      tempDct[i] = "white";
      this.setState({resultBg: tempDct})
      return this.buildItemBox(item, uuidv4(), i);
    });
    this.setState({
      previewStatus: false,
      previewData: null,
      previewIdx:-1,
      allResults:results,
      pg: page,
    });
  }


  sortByDate(sortBy){
    let sortOrd = '';
    if (sortBy === 'status') {
      sortOrd = (this.state.sort[1] === 'asc' && this.state.sort[0] === sortBy) ? 'desc' : 'asc';
    }else if (sortBy === 'file_date' || sortBy === 'adj_lapse_date') {
      sortOrd = (this.state.sort[1] === 'desc' && this.state.sort[0] === sortBy) ? 'asc' : 'desc';
    }
    let sortlst = [sortBy,sortOrd]
    this.setState({ sort: sortlst});
    this.runSearchRequest({sort:sortlst})
  }

  buildSortDropdown() {
    const options = [
      { value: 'status', label: 'Status' },
      { value: 'file_date', label: 'File Date' },
      { value: 'adj_lapse_date', label: 'Lapse Date' }
    ]
    const sort_text_options = {
        'status': 'Sort By: Status',
      'file_date': 'Sort By: File Date',
      'adj_lapse_date': 'Sort By: Lapse Date'
    }
    return <Select
    className="LeadSearch_SortSelect"
    placeholder={sort_text_options[this.state.sort[0]]}
    value={sort_text_options[this.state.sort[0]]}
    options={options}
    isSearchable={false}
    onChange={(e) => {
      this.sortByDate(e.value);
    }}
    components={{
      IndicatorSeparator: () => null
    }}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? null : null
        }
      }),
          indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        marginLeft: '-15px',
      }),
          menu: (baseStyles) => ({
        ...baseStyles,
        marginTop: '-5px',
        marginLeft: '50px',
        width:'70%'
      }),
    }}
    />
  }
  buildSearchResults(allResults) {
    let result_comp = allResults.map((r) => {
      return (r['body']);
    });
    return (
      <>
        <div className="LeadSearch_Results" >
          <div className="LeadSearch_SearchMeta">

            <p>{this.state.total_pages} Results</p>
            {/*{this.buildSortDropdown()}*/}

          </div>
          {this.postId}
          {result_comp}

          {this.buildPageBar()}
        </div>

    </>
    );
  }

  async handleChangePage(page, previewIdx=-1) {
    let pgs = Math.ceil(((this.state.total_pages < 9980) ? this.state.total_pages : 9980) / this.state.numResults);
    if (page <= pgs){
      this.setState({
        loading: true,
      });
      await this.runSearchRequest({page:page});
      this.setState({
        loading: false,
      });
    }
  };



  buildPageBar(){
    let pgs = Math.ceil(((this.state.total_pages < 9980) ? this.state.total_pages : 9980) / this.state.numResults);
    return <>
      <Pagination
          count={pgs}
          className="LeadSearch_Pag"
          page={this.state.pg}
          onChange={(event, pageNumber) => this.handleChangePage(pageNumber)}
          showFirstButton showLastButton
      />
    </>;

  }


  buildNoResults(search_val) {
    return <div>No Results Found For '{search_val.search_val}'</div>;
  }

  switchPreviewState = (data,idx) => {
    let isResultIdx = idx < this.state.pg *this.state.numResults;
    let isNextPg = this.state.pg+1 <= Math.ceil(((this.state.total_pages < 9980) ? this.state.total_pages : 9980) / this.state.numResults);
    let tempDct = this.state.resultBg;
    console.log('leadData',data)
    if (isResultIdx) {
      console.log("switchPreviewState")
      let previewStatus = !this.state.previewStatus;
      let results = this.state.data.map((item, i) => {
        tempDct[i] = idx === i && tempDct[i] === "white" ? "#F5F5F5" : "white";
        if (tempDct[i] !== "white") {
          previewStatus = true;
        } else {
          this.setState({resultBg: tempDct})
        }
        return this.buildItemBox(item, uuidv4(), i);
      });
      for (const key in data) {
        if (data[key] === null || data[key] === undefined) {
          data[key] = '';
        }
      }
      this.setState({previewData: data})
      this.setState({leadData: data})
      this.setState({previewIdx: idx})
      this.setState({allResults: results})
      this.setState({previewStatus: previewStatus})
    } else if (isNextPg) {
      let nextPage = this.state.pg + 1;
      this.handleChangePage(nextPage)
    }else if (isNextPg) {
      let nextPage = this.state.pg + 1;
      this.handleChangePage(nextPage);
    } else {
      // If we're at the end of the last page, reset the index to start from the first item on the first page
      this.setState({
        pg: 1,
      }, () => this.switchPreviewState(this.state.data[0], 0));
    }
    this.executeScroll(idx);
  }

  onSearchInputChange(search_val){
    this.setState({
         search_val: search_val
    });
  }
  onExactSearchChange(){
    this.setState({
         exactSearch: !this.state.exactSearch
    });
  }
  onSearchButtonClick(field,updateField=true){
    if (this.state.loadingType !== 'export') {
      this.setState({loading: true, loadingType: 'search'});
      if (updateField) {
        this.setState({
          main_search_field: field,
          export_url: '',
        });
      }

      this.runSearchRequest()
    }
  }

  async callExport(search_dct){
    this.setState({previewStatus:false,previewData:null,previewIdx:-1,loading:true,loadingType:'export',export_url:''});
    console.log('Running Export...');
    const url = await exportSearch(search_dct,0,this.state.exactSearch).then((res) => {
          return res;
        })
        .catch(() => {
          return null;
        });
    console.log(url && url.status === 200);
    if (url != null){
      this.setState({export_url:url.data,loading:false,loadingType:'search'});
      console.log('export url: ',this.state.export_url);
    }else{
      this.setState({loading:true,loadingType:'search'});
    }
  }
  buildSearchBar(){
    var search_dct = {
      'main_search':'',
      'debtors':'',
      'secured_parties':'',
      'collateral':'',
      'file_num':'',
      '_id':'',
      'hist':'',
      'date':'',
      'lead':this.state.search_val,
      'lead_type':this.state.lead_type,
      'onlyEmp':this.state.onlyEmp,
      'sort':this.state.sort,
    }

    let downloadCsvButton = this.state.export_url ? <a href={this.state.export_url+'.csv'}>CSV</a> : <></>;
    return <div >
      <form className="LeadSearch_Search" onSubmit={(e) => {e.preventDefault(); this.onSearchButtonClick(this.state.main_search_field, false)}}>
        <input
            className="LeadSearch_SearchAgainInput"
            type="text"
            value={this.state.search_val}
            onChange={e => this.onSearchInputChange(e.target.value)}
        ></input>
        <button className="LeadSearch_ExportButton" type="submit">Search</button>
        <button className="LeadSearch_ExportButton" onClick={() => this.callExport(search_dct)}>Export</button>
        <div className="ShowAllCheckBox">
          <input
              type="checkbox"
              id="showAll"
              name="showAll"
              checked={this.state.onlyEmp == 'false'}  // Inverse of onlyEmp because when Show All is checked, we don't want onlyEmp
              onChange={this.onShowAllChange}
          />
          <label htmlFor="showAll">Show All</label>
        </div>
        <div className="LeadSearch_DownUrl">
          {downloadCsvButton}
        </div>
      </form>
    </div>
  }


  render() {
    document.addEventListener("keydown", this.tabFunction, false);
    let allResults = this.state.allResults;
    let est_time = (Math.floor(this.state.numResults / 60 *0.08) + 1).toString();
    const noResults = this.buildNoResults('');
    const loading = this.state.loading && <LoadingSpinner loadingType={this.state.loadingType} est_time={est_time}/>;
    const error = this.state.error && "An Error occurred while searching.";
    let renderThis = this.buildSearchResults(allResults);
    if (this.state.leadViewOpen) {
      renderThis = <div className="LeadSearch_LeadView">
        <button className="LeadSearch_BackButton" onClick={() => this.setState({leadViewOpen: false})}>
          <IoIosArrowBack size={24}/>
        </button>
        <LeadView data={this.state.leadData} isPrev={false} is_debtor={this.state.is_debtor}/>
      </div>;
    }

    if (this.state.loading) renderThis = loading;
    if (this.state.noResults) renderThis = noResults;
    if (this.state.error) renderThis = error;

    return (
      <div className="LeadSearch_SearchPage">
        <div className="LeadSearch_SearchHeader" >
          <Logo className='LeadSearch_SearchLogo' type="nonFixedCorner" />
          {this.buildSearchBar()}
        </div>
        <hr style={{color:"rgba(0, 0, 0, 0.25)", marginLeft:40, marginRight:100,marginTop:30}}/>
        <div className="LeadSearch_SearchContent">
          {renderThis}
          {this.state.previewStatus? <LeadView data={this.state.leadData} isPrev={true} leadViewOpCallback={this.handleLeadViewOpen}
                                               is_debtor={this.state.is_debtor}/> : <></>}
        </div>
      </div>
    );
  }
}


export default LeadSearch;
