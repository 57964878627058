import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowRoundBack, IoIosArrowForward, IoIosArrowBack, IoIosArrowDown, IoIosSearch } from "react-icons/io";
import "../../../css/LeadView.css";

// Components
import EmployeeTable from './EmployeeTable';
import UccEventsTable from './UccEventsTable';

const LeadView = (props) => {
    console.log("props",props)
    let data = props.data;
    let isPrev = props.isPrev;
    let leadViewOpCallback= props.leadViewOpCallback;
    let is_debtor = props.is_debtor;
    const [buttonSelected, setButtonSelected] = useState("All");
    const [searchText, setSearchText] = useState('');
    const [displayAllCompanyNames, setDisplayAllCompanyNames] = useState(false);
    let history = useHistory();

    // Move logic for processing employee and UCC data into helper functions
    const employees = processEmployeeData(data.employee);
    const uccEvents = processUccEventData(data.top_docs.hits.hits);

    const openUccEvents = uccEvents.filter(event => event.status === 'Active' && event.fileNumber === event.origFileNum);
    const allUccEvents = uccEvents.filter(event => event.fileNumber === event.origFileNum);

    const filteredUccEvents = buttonSelected === "Open"
        ? openUccEvents.filter(event => event.collateral.toLowerCase().includes(searchText.toLowerCase()))
        : allUccEvents.filter(event => event.collateral.toLowerCase().includes(searchText.toLowerCase()));

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };
    const handleCompanyNameClick = () => {
        setDisplayAllCompanyNames(!displayAllCompanyNames);
    };

    // Extract the company name and address information to a helper function
    const { comp_name, comp_addr, first_comp_name } = extractCompanyInfo(data.top_docs.hits.hits,isPrev,is_debtor);
    console.log("is_debtor",is_debtor)
    console.log("isPrev",isPrev)
    let searchContainer = (!isPrev && is_debtor) ? <div className="search-container">
        <div className="search-bar">
            <input type="text" placeholder="Search Collateral" onChange={handleSearch} />
            <IoIosSearch className="search-icon" />
        </div>
        <div className="button-group">
            <button
                className={`custom-button ${buttonSelected === 'All' ? 'active' : ''}`}
                onClick={() => setButtonSelected('All')}>
                All ({allUccEvents.length})
            </button>
            <button
                className={`custom-button ${buttonSelected === 'Open' ? 'active' : ''}`}
                onClick={() => setButtonSelected('Open')}>
                Open ({openUccEvents.length})
            </button>
        </div>
    </div> :<></>;
    let show_prev_btn = isPrev ? <button className="lead-view-button" onClick={() =>
        leadViewOpCallback(data)}> Show Full View</button> : <></>;

    let lead_cls = isPrev ? 'prev-lead-view' : "lead-view";
    let lead_header_cls = isPrev ? 'lead-header' : '';
    let comp_name_cls = isPrev ? 'preview-company-name' : 'company-name';
    let comp_add_cls = isPrev ? 'preview-company-address' : 'company-address';
    return  (
        <div className={lead_cls}>
            <div className={lead_header_cls}>
                <div className="company-details">
                    <h1 className={comp_name_cls} onClick={handleCompanyNameClick}>{displayAllCompanyNames ? comp_name : first_comp_name}</h1>
                    <p className={comp_add_cls}>{comp_addr}</p>
                    {searchContainer}
                </div>
                {show_prev_btn}
            </div>

            {employees.length > 0 && <EmployeeTable employees={employees} isPrev={isPrev}/>}
            {is_debtor && <UccEventsTable filteredUccEvents={filteredUccEvents} uccEvents={uccEvents} isPrev={isPrev}/>}
        </div>
    );
};

// Helper functions
function processEmployeeData(emp) {
    let employees = [];
    if (emp) {
        for (let i = 0; i < emp.length; i++) {
            const {
                first_name,
                last_name,
                title,
                email,
                phone_number,
                company,
                linkedin_url,
                company_address
            } = emp[i];

            const employee = {
                name: `${first_name} ${last_name}`,
                position: title ? title : 'N/A',
                email: email,
                number: phone_number ? formattedPhoneNumber(phone_number) : phone_number,
                address: company_address,
                linkedinUrl: linkedin_url
            };

            employees.push(employee);
        }

        employees.sort((a, b) => a.position.localeCompare(b.position));
    }

    return employees;
}

function processUccEventData(data) {
    let uccEvents = [];

    data.forEach(d => {
        uccEvents.push(createUccObject(d._source));

        if(d._source.ammendments && d._source.ammendments.length > 0) {
            d._source.ammendments.forEach(a => {
                uccEvents.push(createUccObject(a));
            });
        }
    });

    return uccEvents;
}

function createUccObject(data) {
    return {
        fileNumber: data.file_num,
        origFileNum: data.orig_file_num,
        securedParty: data.secured_party,
        filingDate: data.file_date,
        collateral: data.collateral,
        status: data.status,
        lapse_date: data.adj_lapse_date,
        type: data.type
    }
}

function processString(str) {
    if (!str) return str;
    return str.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").toLowerCase();
}

function simplifyAddress(addr, city, state) {
    addr = processString(addr);
    city = processString(city);
    state = processString(state);

    const removals = [
        ' drive', ' dr',
        ' street', ' st',
        ' avenue', ' ave',
        ' boulevard', ' blvd',
        ' road', ' rd',
        ' lane', ' ln',
        ' parkway', ' pkwy',
        ' circle', ' cir',
        ' square', ' sq',
        ' heights', ' hts',
        ' highway', ' hwy',
        ' terrace', ' ter',
        // Directionals
        ' n', ' north',
        ' s', ' south',
        ' e', ' east',
        ' w', ' west'
    ];

    for (let word of removals) {
        addr = addr.replace(word, '');
    }

    const states = {
        'al': 'alabama',
        'ak': 'alaska',
        'az': 'arizona',
        'ar': 'arkansas',
        'ca': 'california',
        'co': 'colorado',
        'ct': 'connecticut',
        'de': 'delaware',
        'fl': 'florida',
        'ga': 'georgia',
        'hi': 'hawaii',
        'id': 'idaho',
        'il': 'illinois',
        'in': 'indiana',
        'ia': 'iowa',
        'ks': 'kansas',
        'ky': 'kentucky',
        'la': 'louisiana',
        'me': 'maine',
        'md': 'maryland',
        'ma': 'massachusetts',
        'mi': 'michigan',
        'mn': 'minnesota',
        'ms': 'mississippi',
        'mo': 'missouri',
        'mt': 'montana',
        'ne': 'nebraska',
        'nv': 'nevada',
        'nh': 'new hampshire',
        'nj': 'new jersey',
        'nm': 'new mexico',
        'ny': 'new york',
        'nc': 'north carolina',
        'nd': 'north dakota',
        'oh': 'ohio',
        'ok': 'oklahoma',
        'or': 'oregon',
        'pa': 'pennsylvania',
        'ri': 'rhode island',
        'sc': 'south carolina',
        'sd': 'south dakota',
        'tn': 'tennessee',
        'tx': 'texas',
        'ut': 'utah',
        'vt': 'vermont',
        'va': 'virginia',
        'wa': 'washington',
        'wv': 'west virginia',
        'wi': 'wisconsin',
        'wy': 'wyoming'
    };

    state = states[state] || state;

    return `${addr}${city}${state}`.replace(/\s+/g, '');
}

// simplifyAddress('5710 Bull Run','Columbia','MO')
// simplifyAddress('5710 Bull Run','Columbia','Missouri')


function extractCompanyInfo(hits,isPrev,is_debtor) {
    let comp_name = '';
    let comp_addr = '';
    let first_comp_name = '';
    let processed_comp_name = '';
    let processed_comp_addr = '';
    if (!isPrev) {
        for (let hit of hits) {
            const parties = (is_debtor) ? hit._source.debtors : hit._source.secured_parties;
            for (let i = 0; i < parties.length; i++) {
                let p = parties[i];
                let pname = p.org_name || p.full_name;
                let paddr = (p.addr1 ? p.addr1 + ', ' : '') +
                    (p.city ? p.city + ', ' : '') +
                    (p.state || '');
                let processedPartyName = pname.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").toLowerCase();
                let processedPartyAddr = simplifyAddress(p.addr1, p.city, p.state);

                if (i === 0) {
                    first_comp_name = pname;
                }

                if (p.zip) {
                    paddr += ',' + p.zip;
                    processedPartyAddr += ',' + p.zip;
                }

                if (!processed_comp_name.includes(processedPartyName)) {
                    if (comp_name !== '') {
                        comp_name += '\n';
                        processed_comp_name += '\n';
                    }
                    comp_name += pname;
                    processed_comp_name += processedPartyName;
                }

                if (!processed_comp_addr.includes(processedPartyAddr)) {
                    if (comp_addr !== '') {
                        comp_addr += '\n';
                        processed_comp_addr += '\n';
                    }
                    comp_addr += pname;
                    processed_comp_addr += processedPartyAddr;
                }
            }
        }
    } else {
        let p = (is_debtor) ? hits[0]._source.debtors[0] : hits[0]._source.secured_parties[0] ;
        comp_name = p.org_name || p.full_name;

        comp_addr = (p.addr1 ? p.addr1 + ', ' : '') +
            (p.city ? p.city + ', ' : '') +
            (p.state || '');
        first_comp_name = comp_name;
    }

    return { comp_name, comp_addr, first_comp_name };
}

function formattedPhoneNumber(phoneNumber) {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Format the phone number as +1 (XXX) XXX-XXXX
    const countryCode = digitsOnly.slice(0, 1);
    const areaCode = digitsOnly.slice(1, 4);
    const firstPart = digitsOnly.slice(4, 7);
    const secondPart = digitsOnly.slice(7, 11);
    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
}


export default LeadView;
