// Components
import Header from "../../../Components/Header";

// CSS
import "../../../css/FilingSystemShared.css";

// Pages
import ContactInformation from "../Shared/ContactInformation";
import CheckoutUCC1Container from "./CheckoutUCC1Container";
import ExtraInformation from "./ExtraInformation";
import CollateralInformation from "./CollateralInformation";
import AddAddendumInformation from "./AddAddendumInformation";
import AssignmentUCC1 from "./AssignmentUCC1";
import ConfirmationPage from "../Shared/ConfirmationPage";

// React Router
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetState } from "../../../redux/reducers/ucc1Slice";

const UCC1 = () => {
  // State
  const order = useSelector((state) => state.ucc1.order);

  // List of pages in UCC3.
  const pageRoutes = {
    ContactInformation: {
      self: "/UCC1/ContactInformation",
      next: "/UCC1/Assignment",
    },
    Assignment: {
      self: "/UCC1/Assignment",
      next: "/UCC1/CollateralInformation",
    },
    CollateralInformation: {
      self: "/UCC1/CollateralInformation",
      next: "/UCC1/ExtraInformation",
    },
    ExtraInformation: {
      self: "/UCC1/ExtraInformation",
      addAddendum: "/UCC1/AddAddendumInformation",
      complete: "/UCC1/Checkout",
    },
    AddAddendumInformation: {
      self: "/UCC1/AddAddendumInformation",
      complete: "/UCC1/ExtraInformation",
    },
    Checkout: {
      self: "/UCC1/Checkout",
      complete: "/UCC1/Confirmation",
      addAFiling: "/UCC1/ContactInformation",
    },
    Confirmation: {
      self: "/UCC1/Confirmation",
    },
  };

  return (
    <div>
      {/* Logo*/}
      <Header />
      {/* Content */}
      <div className="FilingSystemPageLayout">
        <Switch>
          <Route path={pageRoutes.ContactInformation.self}>
            <ContactInformation
              isUCC1={true}
              route={pageRoutes.ContactInformation}
            />
          </Route>
          <Route path={pageRoutes.Assignment.self}>
            <AssignmentUCC1 route={pageRoutes.Assignment} />
          </Route>
          <Route path={pageRoutes.CollateralInformation.self}>
            <CollateralInformation route={pageRoutes.CollateralInformation} />
          </Route>
          <Route path={pageRoutes.ExtraInformation.self}>
            <ExtraInformation route={pageRoutes.ExtraInformation} />
          </Route>
          <Route path={pageRoutes.AddAddendumInformation.self}>
            <AddAddendumInformation route={pageRoutes.AddAddendumInformation} />
          </Route>
          <Route path={pageRoutes.Checkout.self}>
            <CheckoutUCC1Container route={pageRoutes.Checkout} />
          </Route>
          <Route path={pageRoutes.Confirmation.self}>
            <ConfirmationPage order={order} resetState={resetState} />
          </Route>
          <Redirect to={pageRoutes.ContactInformation.self} />
        </Switch>
      </div>
    </div>
  );
};

export default UCC1;
