import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import "../css/LandingPage.css";
import Logo from "../Components/Logo";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import searchprev from "../Assets/PitaSearch.png";
import leadprev from "../Assets/LeadSearchPrev.png";
import pitainfo from "../Assets/PitaTechInfo.png";
import samplesum from "../Assets/SampleSum.png";
import myGif from '../Assets/PitaSearch - Quick.gif'; // Path to your GIF file



const LandingPage = () => {

    const [currentContent, setCurrentContent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            goRight();
        }, 10000);
        return () => clearInterval(interval);
    }, [currentContent]);

    const goLeft = () => {
        setCurrentContent(currentContent === 0 ? contents.length - 1 : currentContent - 1);
    }

    const goRight = () => {
        setCurrentContent(currentContent === contents.length - 1 ? 0 : currentContent + 1);
    }

    function buildPitaSearchPrev(){
        return <div className="ContentPrev">
            <h2>
                UCC Search Engine
            </h2>
            <p className="">
                The worlds best UCC search engine. The first search engine to allow you to search by collateral. Designed from the
                ground up to be the easiest and fatest UCC search engine. Quickly run a search and export the results for
                loan officer review.
            </p>
            <img
                className="PrevImg"
                src={searchprev}
                alt="Search Preview"
            />
        </div>

    }
    function buildLeadSearchPrev(){
        return <div className="ContentPrev">
            <h2>
                Equipment Borrower Leads
            </h2>
            <p className="">
                We have collected data on EXACTLY who is borrowing equipment, and we are making in searchable for you! Search
                by company name or city and find the borrowers you are looking for. Export a csv with all the leads form you search.
            </p>
            <img
                className="PrevImg"
                src={leadprev}
                alt="Lead Search Preview"
            />
        </div>

    }
    function buildPitaIntro(){
        return <div className="ContentPrev">
            <h2>
                Welcome to PitaTech!
            </h2>
            <p className="">
                We are working to centralize UCC and equipment data to help you find the information you need. Contact us today at
                info@pitatech.io to learn more about our products and services.
            </p>
            <img
                className="PrevImg"
                src={pitainfo}
                alt="PitaTech Info"
            />
        </div>
    }
    function buildPitaSub(){
        return <div className="ContentPrev">
            <h2>
                Monthly UCC Subscriptions
            </h2>
            <p className="">
                We provide a UCC monthly update on the information that matter most. We keep track
                of borrowers taking loans from outside lenders. UCCs that are close to expiring. Potential conflicts
                in your UCCs. New potential leads for your bank. And much more!
            </p>
            <img
                className="SubImg"
                src={samplesum}
                alt="Sample Summary Preview"
                height="500px"
            />
        </div>

    }
    const contents = [
        buildPitaIntro(),
        buildPitaSearchPrev(),
        buildLeadSearchPrev(),
        buildPitaSub(),
        //...add as many as you want
    ];


    return (
        <div className="landingPage">
            <div className="topBar">
                <div className="logoContainer">
                    <Logo/>
                </div>

            </div>

            <div className="contentWindow">
                <div className="contentText">
                    <h1> Welcome to PitaTech!</h1>
                    <h2>Experience the first ever, UCC search engine. </h2>
                    <p> We are collecting every UCC in the country and putting it in one place.</p>
                    <Link
                        to={{
                            pathname: "/Home",
                        }}
                        type="submit"
                    >
                        <button className="loginButton">Login / Sign Up</button>
                    </Link>
                </div>
                <div className="imgContainer">
                    <img src={myGif} alt="Description of the GIF"  />
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
