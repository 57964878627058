import React from "react";

// Components
import { Grid } from "@mui/material";

// CSS
import "../css/FilingSystemShared.css";

const LabelAndTextArea = ({ setValue, value, label }) => {
  // Events
  const onValueChanged = (e) => setValue(e.target.value);

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1, md: 3 }}
      >
        <Grid item xs={12} md={4}>
          <Grid container justifyContent={{ xs: "start", md: "end" }}>
            {label}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justifyContent="center">
            <textarea
              className="FilingSystemTextAreaWithLabel"
              value={value}
              id={label}
              onChange={onValueChanged}
            />
          </Grid>
        </Grid>
        <Grid item xs={0} md={4}></Grid>
      </Grid>
    </Grid>
  );
};

export default LabelAndTextArea;
