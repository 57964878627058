import React from "react";

// Components
import { Grid } from "@mui/material";

// CSS
import "../css/FilingSystemShared.css";

const LabelAndInput = ({ setValue, value, label, type }) => {
  // Events
  const onInputChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={{ xs: 1, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Grid container justifyContent={{ xs: "flex-start", md: "flex-end" }}>
            {label}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justifyContent="center">
            <input
              className="FilingSystemInputField"
              type={type}
              value={value}
              id={label}
              onChange={onInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LabelAndInput;
