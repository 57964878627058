import React from "react";

const LoadingSpinner = ({loadingType,est_time="0"}) => {
  console.log('loadingType',loadingType)
  let content = <></>
  if (loadingType === "search") {
    content = <p>Loading...</p>;
  }else if (loadingType === "exportFailed") {
    content = <p>Export Failed... Please Try again!</p>;
  }else if (loadingType === "presearch") {
    content = <p>Please enter a search term.</p>;
  }else if (loadingType === "free"){
    content = <p>You are out of free searches. Please contact info@pitatech.io for information about
    signing up for a subscription.</p>;
  }
  else{
    let min_str = (est_time === "1") ? "minute" : "minutes";
    content = <p>
        Generating exported zip....
        This will take about {est_time} {min_str}. Although this could be up to 15 minutes!
    </p>;
  }
  return <div className="Results" style={{marginLeft:100}}>{content} </div>
};

export default LoadingSpinner;
