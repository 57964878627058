import React, { useState } from "react";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import LabelAndSelect from "../../../Components/LabelAndSelect";
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import LabelAndTextArea from "../../../Components/LabelAndTextArea";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { addAAddendum } from "../../../redux/reducers/ucc1Slice";

// Redux
import { useDispatch, useSelector } from "react-redux";

const AddAddendumInformation = ({ route }) => {
  // Redux State
  const { addendums, currentAddendum } = useSelector(
    (state) => state.ucc1.currentData
  );
  const dispatch = useDispatch();

  // State
  const [miscellaneous, setMiscellaneous] = useState(
    addendums[currentAddendum]?.miscellaneous
  );
  const [statementCovers, setStatementCovers] = useState(
    addendums[currentAddendum]?.statementCovers
  );
  const [additionalCollateralInfo, setAdditionalCollateralInfo] = useState(
    addendums[currentAddendum]?.setAdditionalCollateralInfo
  );
  const [description, setDescription] = useState(
    addendums[currentAddendum]?.description
  );
  const [nameAndAddressOfOwner, setNameAndAddressOfOwner] = useState(
    addendums[currentAddendum]?.nameAndAddressOfOwner
  );

  // Methods
  const getDataTree = () => {
    return {
      miscellaneous: miscellaneous,
      statementCovers: statementCovers,
      additionalCollateralInfo: additionalCollateralInfo,
      description: description,
      nameAndAddressOfOwner: nameAndAddressOfOwner,
      id: currentAddendum,
    };
  };

  const resetState = () => {
    setMiscellaneous(null);
    setStatementCovers(null);
    setAdditionalCollateralInfo(null);
    setDescription(null);
    setNameAndAddressOfOwner(null);
  };

  // Events
  const onSubmitClicked = () => {
    dispatch(addAAddendum(getDataTree()));
    resetState();
  };

  return (
    <div className="FilingSystemPageLayout">
      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        {/* Addendum Information Title */}
        <FilingSystemTitle
          title="Adding Addendum"
          subTitle="Optional information in this section. Cost additional $3 fee."
        />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container spacing={5} justifyContent="center">
            {/* Miscellaneous Text */}
            <LabelAndTextArea
              value={miscellaneous}
              setValue={setMiscellaneous}
              label="Miscellaneous"
            />

            {/* Statement Covers */}
            <LabelAndSelect
              value={statementCovers}
              setValue={setStatementCovers}
              label="Statement Covers:"
              options={[
                "None",
                "Filed as a fixture filing",
                "Timber to be cut",
                "As extracted collateral",
              ]}
            />

            {/* Additionaly Collateral Information*/}
            <LabelAndTextArea
              value={additionalCollateralInfo}
              setValue={setAdditionalCollateralInfo}
              label="Additional Collateral Info"
            />

            {/* Collateral is */}
            <LabelAndSelect
              value={statementCovers}
              setValue={setStatementCovers}
              label="Collateral is:"
              options={[
                "None",
                "Admintered by decedent's personal representative",
                "Held in trust",
              ]}
            />

            {/* Debtor is */}
            <LabelAndSelect
              value={statementCovers}
              setValue={setStatementCovers}
              label="Debtor is:"
              options={[
                "None",
                "Filed in connection with a Manufactured-Home Transaction - effective 30 days",
                "Transmitting utility",
              ]}
            />

            {/* Real Estate Title */}
            <Grid item xs={11} md={12}>
              <Grid container justifyContent="center">
                <div className="FilingSystemTitle">Real Estate</div>
              </Grid>
            </Grid>

            {/* Description */}
            <LabelAndTextArea
              value={description}
              setValue={setDescription}
              label="Description"
            />

            {/* Name and Address of Owner */}
            <LabelAndTextArea
              value={nameAndAddressOfOwner}
              setValue={setNameAndAddressOfOwner}
              label="Name and Address of Owner"
            />
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={6} md={2}>
              <Link to={route.complete}>
                <button
                  className="FilingSystemButton"
                  onClick={onSubmitClicked}
                >
                  Submit
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddAddendumInformation;
