import React from "react";

// CSS
import "../../css/FilingSystemShared.css";

// Components
import FilingSystemTitle from "./Shared/FilingSystemTitle";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";

const FilingSystem = () => {
  return (
    <div>
      <Header/>

      <div className="FilingSystemPageLayout">
        <Grid container justifyContent="center" alignItems="center" spacing={5}>
          {/* Filing System Title */}
          <Grid item xs={12}>
            <FilingSystemTitle title="What type of filing?" />
          </Grid>

          {/* UCC1 and UCC3 Buttons */}
          <Grid item xs={11} md={12}>
            <Grid container>
              <Grid item xs={0} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <Grid container justifyContent="space-between" spacing={3}>
                  {/* UCC1 */}
                  <Grid item xs={12} sm={3}>
                    <Link to="/UCC1">
                      <button className="FilingSystemButton">UCC1</button>
                    </Link>
                  </Grid>

                  {/* UCC3 */}
                  <Grid item xs={12} sm={3}>
                    <Link to="/UCC3">
                      <button className="FilingSystemButton">UCC3</button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0} md={4}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FilingSystem;
