import React from "react";

// Components
import { Grid } from "@mui/material";

// CSS
import "../css/FilingSystemShared.css";

const LabelAndSelect = ({ setValue, value, label, options }) => {
  // Events
  const onValueChanged = (e) => setValue(e.target.value);

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1, md: 3 }}
      >
        <Grid item xs={12} md={4}>
          <Grid container justifyContent={{ xs: "start", md: "end" }}>
            {label}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justifyContent="center" margin={{ md: 3 }}>
            <select
              className="FilingSystemSelect"
              value={value}
              id={label}
              onChange={onValueChanged}
            >
              {options.map((item, key) => {
                return (
                  <option key={key} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </Grid>
        </Grid>
        <Grid item xs={0} md={4}></Grid>
      </Grid>
    </Grid>
  );
};

export default LabelAndSelect;
