import React, { useCallback, useState } from "react";

// API
import { performFileNumSearch, performSearch } from "../../../api/searchFLAPI";

// CSS
import "../../../css/FilingSystemShared.css";

// Components
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setFileFound,
  updateFindUCC3State,
} from "../../../redux/reducers/ucc3Slice";
import { color } from "@mui/system";

const FindUCC3 = () => {
  // Redux State
  // const template = useSelector((state) => state.ucc3.exampleFindUCC3File);
  const fileFound = useSelector((state) => state.ucc3.currentData.fileFound);
  const exampleFindUCC3File = useSelector(
    (state) => state.ucc3.exampleFindUCC3File
  );
  const findUCC3State = useSelector((state) => state.ucc3.currentData.findUCC3);
  const dispatch = useDispatch();

  // State
  const [searchValue, setSearchValue] = useState(
    findUCC3State?.searchValue || ""
  );
  const [verifyClicked, setVerifyClicked] = useState(
    findUCC3State?.verifyClicked || false
  );
  const [terminationCheck, setTerminationCheck] = useState(
    findUCC3State?.terminationCheck || false
  );
  const [continuationCheck, setContinuationCheck] = useState(
    findUCC3State?.continuationCheck || false
  );
  const [assignmentCheck, setAssignmentCheck] = useState(
    findUCC3State?.assignmentCheck || false
  );
  const [ammendmentCheck, setAmmendmentCheck] = useState(
    findUCC3State?.ammendmentCheck || false
  );

  // Methods
  const getDataTree = useCallback(() => {
    return {
      searchValue: searchValue,
      verifyClicked: verifyClicked,
      terminationCheck: terminationCheck,
      continuationCheck: continuationCheck,
      assignmentCheck: assignmentCheck,
      ammendmentCheck: ammendmentCheck,
    };
  }, [
    ammendmentCheck,
    assignmentCheck,
    continuationCheck,
    searchValue,
    terminationCheck,
    verifyClicked,
  ]);

  const noInformationInFile = () => {
    if (!verifyClicked) return;
    return (
      <Grid item xs={11} md={12}>
        <Grid container justifyContent="center">
          No Results Found
        </Grid>
      </Grid>
    );
  };

  const searchAPIForSearchValue = async(e, searchValue) => {
    e.preventDefault();
    var search_dct = {
      'main_search':"",
      'debtors':"",
      'secured_parties':"",
      'collateral':"",
      'file_num':searchValue,
      '_id':"",
      'hist':"",
      'date':"",
      'sort':["relevance","desc"],
    }
    // Search For File Number ( Right Now it has no results so I take a template file. )
    const result = await performSearch(search_dct,0,true);
    var file = result.data;
    console.log('Result',result)
    if (file.length > 0) {
      dispatch(setFileFound(file[1]));
    }else{
      dispatch(setFileFound(false));
    }

    // This is for when api is down and you need a template.
    // else dispatch(setFileFound(exampleFindUCC3File));

    // If there was an error thrown, update state or etc. ( All errors were already thrown in console )
    if (result.error) {
    }

    setVerifyClicked(true);
  };

  // Events
  const onVerifyButtonClicked = (e, searchValue) => {
    searchAPIForSearchValue(e, searchValue);
  };

  const onVerifyKeyPress = (e, searchValue) => {
    if (e.key === 'Enter'){
      searchAPIForSearchValue(e, searchValue);
    }
  };

  // Update state and store it in redux UCC3 slice.
  useEffect(() => {
    dispatch(updateFindUCC3State(getDataTree()));
  }, [dispatch, getDataTree]);

  // JSX Methods
  const buildInformationInFile = (fileInfo) => {
    console.log(fileInfo);
    const fileNumber = fileInfo.file_num;
    const debtors = fileInfo.debtors;
    const securedParties = fileInfo.secured_parties;
    const collateral = fileInfo.collateral;
    const imgUrl = fileInfo.img_url ? fileInfo.img_url : "";
    return (
      <Grid item xs={11} md={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={10}>
            {/* Actual container (with border) */}
            <Grid
              container
              minHeight={150}
              border="1px solid grey"
              padding={1}
              gap={2}
            >
              {/* Top Row */}
              <Grid item xs={12}>
                <Grid container>
                  {/* Filing Number Label */}
                  <Grid item xs={4.5} md={1.2}>
                    <Grid container>Filing Number:</Grid>
                  </Grid>

                  {/* Filing Number Data */}
                  <Grid item xs={7.5} md={10.8}>
                    <Grid container>{fileNumber}</Grid>
                  </Grid>

                  {/* Florida Listing  NEEDS TO HAVE LINK UPDATED*/}
                  <Grid item xs={4.5} md={1.2}>
                    <Grid container>
                      <a href={imgUrl}>View Image</a>
                    </Grid>
                  </Grid>

                  {/* PDF*/}
                  <Grid item xs={7.5}>
                    <Grid container>
                      <a target="_blank" rel="noreferrer" href={imgUrl}>
                        PDF
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Bottom Row */}
              <Grid item xs={12}>
                <Grid container gap={1}>
                  {/* Debtor */}
                  {getDebtors(debtors)}

                  {/* Secured Parties */}
                  {getSecuredParties(securedParties)}

                  {/* Collateral */}
                  {buildCollateral(collateral)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Retrieves collateral from data and builds the JSX.
  const buildCollateral = (collateral) => {
    return (
      <Grid item xs={12} md={5.8}>
        <Grid container height="100%">
          <Grid container border="1px solid grey" padding={1}>
            <Grid item xs={12}>
              <Grid container>
                <p>Collateral: </p>
              </Grid>

              <Grid container paddingLeft={1}>
                <p>{collateral}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Builds JSX for both debtors and secured parties.
  const buildDebtorsSecuredParties = (key, name, title) => {
    return (
      <Grid
        container
        key={key}
        border="1px solid grey"
        padding={1}
        maxHeight={200}
      >
        <Grid item xs={12}>
          <Grid container paddingLeft={1}>
            <Grid item xs={6}>
              <Grid container paddingRight={2}>
                <p> {title} </p>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <p>{name}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Retrieves secured parties from data and builds the JSX.
  const getSecuredParties = (securedParties) => {
    if (!securedParties) return;
    let securedPartiesArr = [];

    securedPartiesArr = securedParties.map((securedParty, key) => {
      let name = securedParty['org_name'] ? securedParty['org_name'] : securedParty['full_name'];
      return buildDebtorsSecuredParties(key, name, "Secured Party: ");
    });

    return (
      <Grid item xs={12} md={3}>
        <Grid container gap={1}>
          {securedPartiesArr}
        </Grid>
      </Grid>
    );
  };

  const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      onVerifyButtonClicked(e, searchValue);
    }
  };


  // Retrieves debtors from data and builds the JSX.
  const getDebtors = (debtors) => {
    if (!debtors) return;
    let debtorsArr = [];

    debtorsArr = debtors.map((debtor, key) => {
      let name = debtor['org_name'] ? debtor['org_name'] : debtor['full_name'];
      return buildDebtorsSecuredParties(key, name, "Debtor: ");
    });

    return (
      <Grid item xs={12} md={3}>
        <Grid container gap={1}>
          {debtorsArr}
        </Grid>
      </Grid>
    );
  };
  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        {/* Title */}
        <FilingSystemTitle title="UCC3" />

        {/* Body */}
        <Grid item xs={11} md={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {/* File Search Bar*/}

           
            <Grid item xs={11} md={12}>
            <form>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    justifyContent={{ sm: "start", md: "end" }}
                    alignItems="center"
                  >
                    File Number
                  </Grid>
                </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container justifyContent="center" alignItems="center">
                      <input
                        type="text"
                        className="FilingSystemInputField"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyPress={(e) => onVerifyKeyPress(e, searchValue)}
                      />
                    </Grid>
                  </Grid>

                  {/* Verify Button */}
                  <Grid item xs={12} md={4}>
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid item xs={12} md={3}>
                        <button
                          className="FilingSystemButton"
                          onClick={(e) => onVerifyButtonClicked(e, searchValue)}
                        >
                          Verify
                        </button>
                      </Grid>
                    </Grid>
                  
                  </Grid>
              </Grid>
              </form>
            </Grid>
            
            
            {/* File Found */}
            {fileFound
              ? buildInformationInFile(fileFound)
              : noInformationInFile()}

            {/* Checkboxes */}
            <Grid item xs={11} md={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={0} md={3}></Grid>
                <Grid item xs={12} md={5}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} md={3}>
                      <input
                        type="checkbox"
                        id="termination"
                        checked={terminationCheck}
                        onChange={() => setTerminationCheck(!terminationCheck)}
                      />
                      <label htmlFor="termination">Termination</label>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <input
                        type="checkbox"
                        id="continuation"
                        checked={continuationCheck}
                        onChange={() =>
                          setContinuationCheck(!continuationCheck)
                        }
                      />
                      <label htmlFor="continuation">Continuation</label>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <input
                        type="checkbox"
                        id="assignment"
                        checked={assignmentCheck}
                        onChange={() => setAssignmentCheck(!assignmentCheck)}
                      />
                      <label htmlFor="assignment">Assignment</label>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <input
                        type="checkbox"
                        id="ammendment"
                        checked={ammendmentCheck}
                        onChange={() => setAmmendmentCheck(!ammendmentCheck)}
                      />
                      <label htmlFor="ammendment">Ammendment</label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} md={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Next Button */}
        {fileFound && (
          <Grid item xs={11} md={4}>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Link to="/UCC3/ContactInformation">
                  <button className="FilingSystemButton">Next</button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FindUCC3;
