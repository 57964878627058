// React Router
import { Link } from "react-router-dom";

// Components
import FilingSystemTitle from "../Shared/FilingSystemTitle";
import { Grid } from "@mui/material";

// CSS
import "../../../css/FilingSystemShared.css";

// Redux
import { updateCollateral } from "../../../redux/reducers/ucc1Slice";
import { useSelector, useDispatch } from "react-redux";

const CollateralInformation = ({ nextPage, updateData, route }) => {
  // Redux State
  const collateral = useSelector((state) => state.ucc1.currentData.collateral);
  const dispatch = useDispatch();

  // Events
  const onCollateralChange = (e) => dispatch(updateCollateral(e.target.value));

  return (
    <div className="FilingSystemPageLayout">
      <Grid container justifyContent="center" spacing={5}>
        {/* Collateral Information Title */}
        <FilingSystemTitle title="What's the collateral?" />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={9} md={4}>
              <textarea
                type="textArea"
                className="FilingSystemTextArea"
                value={collateral}
                id="collateral"
                onChange={onCollateralChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={9} md={4}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={6}>
                  <Link to={route.next}>
                    <button className="FilingSystemButton">Next</button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CollateralInformation;
