import React, { useEffect, useState } from "react";

// Components
import { Grid } from "@mui/material";
import FilingSystemTitle from "./FilingSystemTitle";
import LabelAndInput from "../../../Components/LabelAndInput";

// React Router
import history from "../../../history";

// CSS
import "../../../css/FilingSystemShared.css";

const DebtorsInformationUCC3 = ({
  file,
  UCC1,
  id,
  addADebtor,
  submitDebtor,
}) => {
  // State
  const [isOrganization, setIsOrganization] = useState(
    file?.isOrganization || ""
  );
  const [name, setName] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  // Methods
  const getDataTree = () => {
    return {
      name: name,
      addressOne: addressOne,
      addressTwo: addressTwo,
      city: city,
      state: state,
      zip: zip,
      isOrganization: isOrganization,
      id: id,
    };
  };

  const resetState = () => {
    setIsOrganization("");
    setName("");
    setAddressOne("");
    setAddressTwo("");
    setCity("");
    setState("");
    setZip("");
  };

  const addAnother = () => {
    addADebtor({});
    resetState();
  };
  const submitADebtor = () => submitDebtor(getDataTree());
  // Events
  const onOrganizationCheckClicked = () => setIsOrganization(true);
  const onPersonCheckClicked = () => setIsOrganization(false);
  const onNextClicked = () => submitADebtor();
  const onSubmitClicked = () => submitADebtor();
  const onAddAnotherDebtorClicked = () => addAnother();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "POP" && !UCC1) {
        history.go(1);
      }
    });
    return unlisten;
  }, [UCC1]);

  return (
    <div className="FilingSystemPageLayout">
      <Grid container spacing={5} justifyContent="center">
        {/* Title */}
        <FilingSystemTitle title="Who are the Debtors?" />

        {/* Form */}
        <Grid item xs={11} md={12}>
          <Grid container spacing={3}>
            {/* Document Data from DB (UCC3) */}
            {file && (
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 1, md: 3 }}>
                  <Grid item xs={0} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    {file.name}
                    {file.addressOne}
                    {file.addressTwo}
                    {file.city}
                    {file.state}
                    {file.zip}
                  </Grid>
                  <Grid item xs={0} md={4}></Grid>
                </Grid>
              </Grid>
            )}

            {/* Checkboxes */}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 1, md: 3 }}
              >
                <Grid item xs={0} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <Grid container justifyContent="space-between">
                    {/* Organization */}
                    <Grid item>
                      <Grid container>
                        <input
                          type="checkbox"
                          id="organization"
                          checked={isOrganization}
                          onChange={onOrganizationCheckClicked}
                        />
                        <div>Organization</div>
                      </Grid>
                    </Grid>

                    {/* Person */}
                    <Grid item>
                      <Grid container>
                        <input
                          type="checkbox"
                          checked={!isOrganization}
                          id="person"
                          onChange={onPersonCheckClicked}
                        />
                        <div>Person</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} md={4}></Grid>
              </Grid>
            </Grid>

            {/* Organization Name */}
            <LabelAndInput
              setValue={setName}
              value={name}
              label={isOrganization ? "Organization Name" : "Name"}
              type="text"
            ></LabelAndInput>

            {/* Address Line 1 */}
            <LabelAndInput
              setValue={setAddressOne}
              value={addressOne}
              label="Address Line 1 "
              type="text"
            ></LabelAndInput>

            {/* Address Line 2*/}
            <LabelAndInput
              setValue={setAddressTwo}
              value={addressTwo}
              label="Address Line 2 "
              type="text"
            ></LabelAndInput>

            {/* City */}
            <LabelAndInput
              setValue={setCity}
              value={city}
              label="City"
              type="text"
            ></LabelAndInput>

            {/* State */}
            <LabelAndInput
              setValue={setState}
              value={state}
              label="State"
              type="text"
            ></LabelAndInput>

            {/* Zip */}
            <LabelAndInput
              setValue={setZip}
              value={zip}
              label="Zip"
              type="text"
            ></LabelAndInput>
          </Grid>
        </Grid>

        {/* Add another Debtor */}
        {UCC1 && (
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={{ xs: 1, md: 3 }}>
              <Grid item xs={11} md={4}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6}>
                    <div>Add another Debtor</div>
                  </Grid>
                  <Grid item xs={6}>
                    <button
                      className="FilingSystemButton"
                      onClick={onAddAnotherDebtorClicked}
                    >
                      +
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Buttons */}
        <Grid item xs={11} md={4}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6}>
              {UCC1 && (
                <button className="FilingSystemButton" onClick={onNextClicked}>
                  Next
                </button>
              )}
              {!UCC1 && (
                <button
                  className="FilingSystemButton"
                  onClick={onSubmitClicked}
                >
                  Submit
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DebtorsInformationUCC3;
