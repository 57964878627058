import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FilingSystemTitle from "./FilingSystemTitle";

const Checkout = ({
  cart,
  completeTransaction,
  completeRoute,
  deleteItem,
  addAnotherItem,
  addAnotherItemText,
  addAnotherItemRoute,
  body,
}) => {
  // const handleDeleteItemClicked = () => deleteItem();
  const handleCheckoutClicked = () => completeTransaction();
  const handleAddAnotherItemClicked = () => addAnotherItem();

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={5}>
      <Grid item xs={11} md={12}>
        <FilingSystemTitle title="Checkout" />
      </Grid>

      {/* Body of Checkout. Whatever the list of items are. */}

      <Grid item xs={11} md={12}>
        <Grid container justifyContent="center" alignItems="center">
          {body}
        </Grid>
      </Grid>

      {/* Buttons */}
      <Grid item xs={11} md={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={6} md={1}>
            <Grid container justifyContent="center">
              <Link to={addAnotherItemRoute}>
                <button
                  className="FilingSystemButton"
                  onClick={handleAddAnotherItemClicked}
                >
                  {addAnotherItemText}
                </button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={6} md={1}>
            <Grid container justifyContent="center">
              <Link to={cart.length > 0 && completeRoute}>
                <button
                  className="FilingSystemButton"
                  onClick={handleCheckoutClicked}
                >
                  Checkout
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Checkout;
