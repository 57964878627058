import React, { useState } from "react";

// Pages
import AssignmentList from "../Shared/AssignmentList";
import Debtor from "../Shared/DebtorInformation";
import SecuredParty from "../Shared/SecuredPartyInformation";

// React Router
import { Redirect, Route, Switch } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentSecuredParty,
  updateCurrentDebtor,
  updateSpecificSecuredParty,
  updateSpecificDebtor,
  deleteSecuredParty,
  deleteDebtor,
  addADebtor,
  addASecuredParty,
} from "../../../redux/reducers/ucc3Slice";

const Assignment = ({ route }) => {
  // Redux State
  const { debtors, securedParties, currentSecuredParty, currentDebtor } =
    useSelector((state) => state.ucc3.currentData);
  const dispatch = useDispatch();

  // State
  // Navigation
  const pageRoutes = {
    debtors: "/UCC3/Assignment/Debtors",
    securedParties: "/UCC3/Assignment/SecuredParties",
    list: "/UCC3/Assignment/List",
    viewDebtor: "/UCC3/Assignment/edit/Debtor",
    viewSecuredParty: "/UCC3/Assignment/edit/SecuredParty",
  };
  const [currentRoute, setCurrentRoute] = useState(pageRoutes.list);

  // Methods
  const viewDebtorOrSecuredParty = (type, data, id) => {
    if (!type) console.log("Type error in viewDebtorOrSecuredParty: ", type);
    if (type === "Debtor") {
      dispatch(updateCurrentDebtor(id));
      setCurrentRoute(pageRoutes.viewDebtor);
    }
    if (type === "SecuredParty") {
      dispatch(updateCurrentSecuredParty(id));
      setCurrentRoute(pageRoutes.viewSecuredParty);
    }
  };

  const submitSecuredParty = (data) => {
    dispatch(updateSpecificSecuredParty(data));
    setCurrentRoute(pageRoutes.list);
  };

  const submitDebtor = (data) => {
    dispatch(updateSpecificDebtor(data));
    setCurrentRoute(pageRoutes.list);
  };

  return (
    <div className="FilingSystemPageLayout">
      <Switch>
        <Route path={pageRoutes.list}>
          <AssignmentList
            route={route}
            view={viewDebtorOrSecuredParty}
            type="UCC3"
            deleteSecuredParty={deleteSecuredParty}
            deleteDebtor={deleteDebtor}
            addADebtor={addADebtor}
            addASecuredParty={addASecuredParty}
          />
        </Route>

        <Route path={pageRoutes.viewDebtor}>
          <Debtor
            file={debtors[currentDebtor]}
            submitDebtor={submitDebtor}
            UCC1={false}
            id={currentDebtor}
          />
        </Route>

        <Route path={pageRoutes.viewSecuredParty}>
          <SecuredParty
            file={securedParties[currentSecuredParty]}
            submitSecuredParty={submitSecuredParty}
            UCC1={false}
            id={currentSecuredParty}
          />
        </Route>
      </Switch>
      <Redirect to={currentRoute} />
    </div>
  );
};

export default Assignment;
