import axios from "axios";

const uccFLAPI = axios.create({
  baseURL: `https://vll8by2bqi.execute-api.us-east-1.amazonaws.com`,
});

const expFLAPI = axios.create({
  baseURL: `https://xgrheejoyusqazlyrxryvzeyru0pcvto.lambda-url.us-east-1.on.aws`,
});
export { uccFLAPI,expFLAPI };
