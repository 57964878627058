import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import {subtractSearch, updateUser} from '../redux/reducers/userSlice';
import {connect} from "react-redux";



// CSS
import "react-awesome-button/dist/styles.css";
import "../css/Home.css";
import {withAuthenticator} from "@aws-amplify/ui-react";
import SearchDropdown from '../Components/SearchDropdown';


const Home = (props) => {
    if (props.user && props.user.username) {
        props.dispatch(updateUser(props.user));
    }

      // State
    const [searchValue, setSearchValue] = useState("");
    const [searchField, setSearchField] = useState('debtors'); // Default search field

    const defaultValue = " ";

     // Events
    const handleChange = (e) => setSearchValue(e.target.value);

    const handleSearchFieldChange = selectedOption => {
        setSearchField(selectedOption.value);
        handleSubmit();
    };

    const handleSubmit = () => {
        props.dispatch(subtractSearch());

        props.history.push({
            pathname: "/SearchRouter",
            search_val: searchValue || defaultValue,
            search_field: searchField || 'debtors'
        })
        console.log('HOME',searchValue)
        console.log('HOME',searchField)
    };


    return (
    <div className="Home">
        <Header />
        <div className="Home_Content">
            {/* Title Content */}
            <div className="Home_TitleContent">
                <h1>Every UCC ever filed. At your fingertips.</h1>
                <h2 className="Home_SubTitle">Blazing fast search engine for any collateral.</h2>
            </div>

          {/* Search Box */}
            <form className="Home_SearchBox" onSubmit={handleSubmit}>
                <div className="Home_SearchInputs">
                    <div className="Home_SearchField">
                        <input
                            className="Home_SearchField_Input"
                            type="search"
                            value={searchValue}
                            onChange={handleChange}
                            placeholder="Search by borrower name"
                        />
                    </div>
                    <SearchDropdown className="Home_SearchButton" onChange={handleSearchFieldChange} value={searchField} onControlClick={handleSubmit}/>
                </div>
            </form>
        </div>

    </div>
    );
    };

function mapStateToProps(state,props) {
    return props
}

export default withAuthenticator(connect(mapStateToProps)(Home));

