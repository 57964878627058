import { API } from "aws-amplify";
import { performAPIErrorChecking, performAPIResultsFound } from "./APIServices";

// Retrieve all items from UCCs dynamodb table.
const fetchUccsFromDynamoDb = () => {
  const apiName = "DynamodbUCCAPI";
  const path = "/uccs";
  const myInit = {
    headers: {},
  };
  return API.get(apiName, path, myInit)
    .then((res) => performAPIResultsFound(res))
    .catch((error) => performAPIErrorChecking(error));
};

// Post a UCC in DynamoDb table.
const postUccInDynamoDb = (data) => {
  if (!data) return console.log("Data was null in postUccInDynamoDb", data);
  const type = "Post a UCC In DynamoDb";
  const apiName = "DynamodbUCCAPI";
  const path = "/uccs";
  const myInit = {
    body: data,
    headers: {},
  };
  return API.post(apiName, path, myInit)
    .then((res) => performAPIResultsFound(res, type))
    .catch((error) => performAPIErrorChecking(error, type));
};

// Posts an Order in DynamoDb order table.
const postOrderInDynamoDb = (order) => {
  if (!order)
    return console.log("Order was null in postOrderInDynamoDb.", order);

  const type = "Post an Order In DynamoDb";
  const apiName = "DynamodbOrdersAPI";
  const path = "/orders";
  const myInit = {
    body: order,
    headers: {},
  };
  
  return API.post(apiName, path, myInit)
    .then((res) => performAPIResultsFound(res, type))
    .catch((error) => performAPIErrorChecking(error, type));
};

export { fetchUccsFromDynamoDb, postUccInDynamoDb, postOrderInDynamoDb };
