import React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// API
import { performSearch,exportSearch } from "../../api/searchFLAPI";

// Components
import LoadingSpinner from "../../Components/LoadingSpinner";
import Logo from "../../Components/Logo";
import { v4 as uuidv4 } from "uuid";
import Pagination from '@mui/material/Pagination';
import Select from 'react-select'
import SearchPreview from "./SearchPreview";
import SearchDropdown from "../../Components/SearchDropdown";

import fileDownload from 'js-file-download';


// Redux
import {connect } from "react-redux";

// CSS
import "../../css/SearchPage.css";
import axios from "axios";
/*
    Mostly following this tutorial:
    https://www.antstack.io/blog/getting-started-with-open-search/
    https://docs.aws.amazon.com/opensearch-service/latest/developerguide/search-example.html


    The authentication wouldn't work. The key is to add the IAM Role created by the
    lambda function to a user/backend role (i did both) in the opensearch dashboard.

    https://docs.aws.amazon.com/opensearch-service/latest/developerguide/fgac.html#fgac-mapping

    There's a comment on here that also added some clarificaiton:
    https://stackoverflow.com/questions/61966703/aws-elasticsearch-console-how-to-access-to-indices-tab-in-my-es-domain
*/

class SearchPage extends React.Component {
  // State
  constructor(props) {
    super(props);
    let numResults = 20;
    const  search_val  = props.search_val || '';
    const search_field = props.search_field || "debtors"; // Default to "debtors" if not provided
    console.log("SEARCH PROPS",props)
    const  route  = props.route;
    this.state = {
      exactSearch: true,
      search_val: search_val,
      route: route,
      debtor_val: '',
      sp_val: '',
      collateral_val: '',
      file_num_val: '',
      main_search_val: '',
      date_val: '',
      postId: null,
      loading: true,
      loadingType:'search',
      noResults: false,
      error: null,
      data: [],
      pg: 1,
      total_pages: 0,
      numResults:numResults,
      showFilt: false,
      allResults: [],
      previewStatus: false,
      previewData: null,
      previewIdx: -1,
      resultBg: {},
      sort: ["status",'asc'],
      main_search_field: search_field,
      [this.getSearchFieldStateKey(search_field)]: search_val,
      exclude:[]
    };
    this.dispatch = props.dispatch;
    this.scrollRefs = Array.from(Array(numResults)).map((_, i) => React.createRef())

    this.switchFiltState = this.switchFiltState.bind(this)
    this.switchPreviewState = this.switchPreviewState.bind(this)
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this)
    this.onExactSearchChange = this.onExactSearchChange.bind(this)
    this.tabFunction = this.tabFunction.bind(this)
    this.alterExclude = this.alterExclude.bind(this)
  }

  getSearchFieldStateKey(field) {
    // This function returns the state key corresponding to the search field
    const fieldStateMap = {
      'main_search': 'main_search_val',
      'debtors': 'debtor_val',
      'secured_parties': 'sp_val',
      'collateral': 'collateral_val',
      'file_num': 'file_num_val',
    };
    return fieldStateMap[field] || 'main_search_val';
  }

  set_search_val = (val) => {
    let val_dct = {
      'main_search':'main_search_val',
      'debtors':'debtor_val',
      'secured_parties':'sp_val',
      'collateral':'collateral_val',
      'file_num':'file_num_val',
    }
    this.state[val_dct[this.state.main_search_field]] = val;

  }

  async runSearchRequest({page=1,sort=null}= {}){
    this.resetPage(page);
    this.set_search_val(this.state.search_val)
    var search_dct = {
      'main_search':this.state.main_search_val,
      'debtors':this.state.debtor_val,
      'secured_parties':this.state.sp_val,
      'collateral':this.state.collateral_val,
      'file_num':this.state.file_num_val,
      '_id':this.state.file_num_val,
      'hist':this.state.file_num_val,
      'date':this.state.date_val,
      'lead':'',
      'sort':(sort !== null) ? sort : this.state.sort,
      'exclude':this.state.exclude,
      [this.state.main_search_field]: this.state[this.getSearchFieldStateKey(this.state.main_search_field)]
  }
    const result = await performSearch(search_dct,page-1,this.state.exactSearch);
    var data = result.data;
    if (typeof data !== 'string') {
      const total_pgs = data[0].total_pgs;
      data = data.slice(1);
      const allResults = this.getSearchResults(data);
      this.setState({
        pg: page
      });
      if (data) {
        if (data.length > 0)
          this.setState({data: data, allResults: allResults, loading: false, noResults: false, total_pages: total_pgs});

        if (data.length <= 0)
          this.setState({data: data, allResults: allResults, loading: false, noResults: true});
      }
      console.log("RESULTS",allResults)
      if (result.error)
        this.setState({
          data: data,
          loading: false,
          noResults: false,
          error: true,
          allResults:allResults
        });
    }else{
      console.log('NO MORE SEARCHESS')
      this.setState({loading: true,loadingType:'free'});
    }
  }

  tabFunction(event){

    if (event.key === "Tab" && !this.state.loading      ) {
      event.preventDefault();
      //Do whatever when esc is pressed
      let idx = this.state.previewIdx + 1;
      this.setState({previewIdx:idx})
      this.switchPreviewState(event,this.state.data[idx], idx)
    }
  }
  alterExclude(item,appendItem){
    if (appendItem){
      this.state.exclude = [...this.state.exclude, item]
    } else {
      this.state.exclude = this.state.exclude.filter((it, i) => it !== item)
    }
  }

  async componentDidMount() {
    // Retrieves data from API using the "params" as guidance.
    console.log("MOUNTED COMPONENT")
    if (this.state.search_val !== ''){
      await this.runSearchRequest();
    }
  }

  async componentDidUpdate(){
  }

  getAmmendments(data) {
    const history = data.ammendments;
    if (history !== null && history.length > 0){
      let statusLst = history.map((ammend) => {
        return ammend['type'].includes('Termination');
      });
      const ammendLength = history.length;
      const status = statusLst.includes(true) ? "Terminated" : "Active";
      // Return two values, the ammendLength and the status
      return [ammendLength, status];
    } else {
      return null;
    }
  }
  getSearchResults(data) {
    if (!data) return;
    console.log("all results",data)
    // Takes the data from API and passes each item and it's key as a parameter and returns what to render.
    let allResults = data.map((item,idx) => {
      return this.buildItemBox(item, uuidv4(),idx);
    });

    return allResults;
  }
  executeScroll = (idx) => {
    if (this.scrollRefs.length > idx && this.scrollRefs[idx].current !== null){
      this.scrollRefs[idx].current.scrollIntoView()
    }
  }

  buildItemBox(data, key,idx) {
    let tempDct = this.state.resultBg;
    tempDct[idx] = tempDct[idx] !== undefined ? tempDct[idx] : "white";
    this.setState({resultBg : tempDct});
    const expDate = data.lapse_date;
    let img_url = data.img_url;
    let status =data.status;
    const haveImage = img_url !== null || status === "Terminated"
    let collateral = (data.collateral == null) ? 'No collateral found.' : data.collateral;
    collateral = (haveImage) ? collateral : "We are working with the state to get access to this UCC Image. Search this filing on this link: https://bsd.sos.mo.gov/LoginWelcome.aspx?lobID=0";
    data.collateral = collateral;
    data.collat_style = (haveImage) ? {color: "rgba(0, 0, 0, 0.55)"} : {color: "rgb(152,122,16)"};
    const debtors = (data.debtors[0].org_name.length >0) ?  data.debtors[0].org_name : data.debtors[0].full_name;
    const ammendments = this.getAmmendments(data);
    let ammendLength = 0;
    if (ammendments !== null){
      ammendLength = ammendments[0];
    }
    // status = new Date() < new Date(expDate) && status !== "Terminated" ? "Active" : "Terminated";
    const statusColor = status === "Active" ? "#22F69D" : "#b7b7b7";
    return (
        {'body':
              <div ref={this.scrollRefs[idx]} className="SearchPage_SingleResult" onClick={event => this.switchPreviewState(event,data,idx)} style={{backgroundColor:this.state.resultBg[idx]}}>
                <div className="SearchPage_ResultHead">
                  <h3 className="SearchPage_ResultSP">{debtors} </h3>
                  <div  className="SearchPage_ResultStat" style={{color:statusColor, borderColor:statusColor}}><p >{status}</p></div>
                </div>
                <p className="SearchPage_ResultCollat" style={data.collat_style}>{collateral}</p>
                <p className="SearchPage_ResultAmend">{ammendLength} Amendments</p>
              </div>,
          'date':expDate,
          'rel':idx,
          'status':status,
        }
    );
  }

  resetPage(page){
    let tempDct = this.state.resultBg;
    let results = this.state.data.map((item, i) => {
      tempDct[i] = "white";
      this.setState({resultBg: tempDct})
      return this.buildItemBox(item, uuidv4(), i);
    });
    this.setState({
      pg: page,
      previewStatus: false,
      previewData: null,
      previewIdx:-1,
      allResults:results
    });
  }

  handleChangePage = async(event, page) => {
    this.setState({
      loading: true,
    });
    await this.runSearchRequest({page:page});
    this.setState({
      loading: false,
    });
  };

  buildPageBar(){
    var pgs = Math.ceil(((this.state.total_pages < 9980) ? this.state.total_pages : 9980) / this.state.numResults);
    return <>
      <Pagination
          count={pgs}
          className="SearchPage_Pag"
          page={this.state.pg}
          onChange={(event, pageNumber) => this.handleChangePage(event, pageNumber)}
          showFirstButton showLastButton
      />
    </>;

  }


  sortByDate(sortBy){
    let sortOrd = '';
    if (sortBy === 'status') {
      sortOrd = (this.state.sort[1] === 'asc' && this.state.sort[0] === sortBy) ? 'desc' : 'asc';
    }else if (sortBy === 'file_date' || sortBy === 'adj_lapse_date') {
      sortOrd = (this.state.sort[1] === 'desc' && this.state.sort[0] === sortBy) ? 'asc' : 'desc';
    }
    let sortlst = [sortBy,sortOrd]
    this.setState({ sort: sortlst});
    this.runSearchRequest({sort:sortlst})
  }

  buildSortDropdown() {
    const options = [,
      { value: 'status', label: 'Status' },
      { value: 'file_date', label: 'File Date' },
      { value: 'adj_lapse_date', label: 'Lapse Date' }
    ]
    const sort_text_options = {
      'status': 'Sort By: Status',
      'file_date': 'Sort By: File Date',
      'adj_lapse_date': 'Sort By: Lapse Date'
    }
    return <Select
        className="SearchPage_SortSelect"
        placeholder={sort_text_options[this.state.sort[0]]}
        value={sort_text_options[this.state.sort[0]]}
        options={options}
        isSearchable={false}
        onChange={(e) => {
          this.sortByDate(e.value);
        }}
        components={{
          IndicatorSeparator: () => null
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: state.isFocused ? null : null
            }
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            marginLeft: '-15px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: '-5px',
            marginLeft: '50px',
            width:'70%'
          }),
        }}
    />
  }
  buildSearchResults(allResults) {
    // Sort all results list by the value 'date'
    // allResults.sort(function(first, second) {
    //   return second.date - first.date;
    // });
    let result_comp = allResults.map((r) => {
      return (r['body']);
    });
    return (
        <>
          <div className="SearchPage_Results" >
            <div className="SearchPage_SearchMeta">
              <p>{this.state.total_pages} Results</p>
              {this.buildSortDropdown()}

            </div>
            {this.postId}
            {result_comp}

            {this.buildPageBar()}
          </div>

        </>
    );
  }

  buildNoResults(search_val) {
    return <div>No Results Found For '{search_val.search_val}'</div>;
  }


  onFiltChange(value,state_var){
    var state_dct = {};
    state_dct[state_var] = value;
    this.setState(state_dct);
  }


  buildInput(pHold,state_var){
    return(
        <input
            className='SearchPage_FiltInput'
            placeholder={pHold}
            type="text"
            value={this.state[state_var]}
            onChange={e => this.onFiltChange(e.target.value,state_var)}
        >
        </input>);
  }

  buildFilter(){
    return <>
      <div className='SearchPage_Filter'>
        {this.buildInput('Debtor','debtor_val')}
        {this.buildInput('Secured Parties','sp_val')}
        {this.buildInput('Collateral','collateral_val')}
        {this.buildInput('File Number','file_num_val')}
        {/*{this.buildInput('Date','date_val')}*/}
        <div className="SearchPage_FilterExactCheckBox">
          <FormControlLabel
              className="SearchPage_FilterExactCheckBox"
              checked={this.state.exactSearch}
              onChange={this.onExactSearchChange}
              control={
                <Checkbox name="gilad" />
              }
              label="Match Exact Search"
          />
        </div>
        <div className="SearchPage_FilterExactCheckBox_Small">
          <FormControlLabel
              className="SearchPage_FilterExactCheckBox"
              checked={this.state.exactSearch}
              onChange={this.onExactSearchChange}
              control={
                <Checkbox name="gilad" />
              }
              label="Exact"
          />
        </div>
      </div>
    </>
  }

  switchFiltState(){
    this.setState({showFilt : !this.state.showFilt})
  }
  switchPreviewState = (event, data,idx) => {
    let isResultIdx = idx < this.state.numResults && idx < this.state.data.length;
    let isNextPg = this.state.pg+1 < Math.ceil(((this.state.total_pages < 9980) ? this.state.total_pages : 9980) / this.state.numResults);
    let tempDct = this.state.resultBg;
    if (isResultIdx) {
      console.log("switchPreviewState")
      let previewStatus = !this.state.previewStatus;
      let results = this.state.data.map((item, i) => {
        tempDct[i] = idx === i && tempDct[i] === "white" ? "#F5F5F5" : "white";
        if (tempDct[i] !== "white") {
          previewStatus = true;
        } else {
          this.setState({resultBg: tempDct})
        }
        return this.buildItemBox(item, uuidv4(), i);
      });
      for (const key in data) {
        if (data[key] === null || data[key] === undefined) {
          data[key] = '';
        }
      }
      this.setState({previewData: data})
      this.setState({previewIdx: idx})
      this.setState({allResults: results})
      this.setState({previewStatus: previewStatus})
    } else if (isNextPg) {
      let nextPage = this.state.pg + 1;
      this.handleChangePage(event, nextPage)
    }
    this.executeScroll(idx);
  }

  onSearchInputChange(search_val){
    this.setState({
      search_val: search_val
    });
  }
  onExactSearchChange(){
    this.setState({
      exactSearch: !this.state.exactSearch
    });
  }

  clear_fields(){
    this.setState({
      debtor_val: '',
      sp_val: '',
      collateral_val: '',
      file_num_val: '',
      date_val: '',
      export_url:''
    });
  }
  onSearchButtonClick(field,updateField=true){
    if (!this.state.loading && this.state.loadingType !== 'export') {
      if (updateField) {
        this.setState({
          pg: 1,
          main_search_field: field,
          export_url: ''
        });
      } else {
        this.setState({
          pg: 1,
          export_url: ''
        });
      }
      this.runSearchRequest()
    }
  }

  async callExport(search_dct){
    if(this.state.loadingType != 'free') {
      this.setState({
        previewStatus: false,
        previewData: null,
        previewIdx: -1,
        loading: true,
        loadingType: 'export',
        export_url: ''
      });

      search_dct['exclude'] = this.state.exclude;
      console.log('Running Export...');
      const url = await exportSearch(search_dct, 0, this.state.exactSearch).then((res) => {
        return res;
      }).catch((e) => {
        return null;
      });
      console.log(url)
      console.log(url && url.status === 200);
      if (url != null) {
        this.setState({export_url: url.data, loading: false, loadingType: 'search'});
        console.log('export url: ', this.state.export_url);
      } else {
        console.log('failed to load export');
        this.setState({loading: true, loadingType: 'search'});
      }
    }
  }
  buildSearchHeader(){
    var search_dct = {
      'main_search':this.state.main_search_val,
      'debtors':this.state.debtor_val,
      'secured_parties':this.state.sp_val,
      'collateral':this.state.collateral_val,
      'file_num':this.state.file_num_val,
      '_id':this.state.file_num_val,
      'hist':this.state.file_num_val,
      'date':this.state.date_val,
      'sort':this.state.sort,
      'lead':'',
      'exclude':this.state.exclude,
    }
    let downloadPdfButton = this.state.export_url ? <a href={this.state.export_url+'.pdf'}>PDF</a> : <></>;
    let downloadCsvButton = this.state.export_url ? <a href={this.state.export_url+'.csv'}>CSV</a> : <></>;


    return <>
    <form  onSubmit={(e) => {e.preventDefault(); this.onSearchButtonClick(this.state.main_search_field, false)}}>
      <div className="SearchPage_SearchHeader" >
        <Logo className='SearchPage_SearchLogo' type="nonFixedCorner" />
        <div className="SearchPage_Search">
            <input
                className="SearchPage_SearchAgainInput"
                type="text"
                value={this.state.search_val}
                onChange={e => this.onSearchInputChange(e.target.value)}
            ></input>
            <div className='SearchPage_SearchButtonContainer'>
              <button className="SearchPage_SearchAgainButton" type="submit">{<SearchDropdown onChange={(e) => {
                this.clear_fields();
                this.onSearchButtonClick(e.value);
              }} value={this.state.main_search_field} onControlClick={()=>{this.onSearchButtonClick(this.state.search_val,false)}}/>}</button>

              <button className="SearchPage_FilterButton" onClick={this.switchFiltState}>Filter</button>
              <button className="SearchPage_ExportButton" onClick={() => this.callExport(search_dct)}>Export</button>
              <div className="SearchPage_DownUrl">
                {downloadCsvButton}
                {downloadPdfButton}
              </div>
            </div>
        </div>
      </div>
      {this.state.showFilt? this.buildFilter() :<></>}
    </form>
    </>

  }

  buildResultPreview(){
    const page_preview_details = {
      'isMain': true,
      'state': this.state,
      'dispatch': this.dispatch,
    }

    return <SearchPreview location={page_preview_details} excludeCallback={this.alterExclude}/>
  }

  render() {
    document.addEventListener("keydown", this.tabFunction, false);
    let allResults = this.state.allResults;
    let est_time = (Math.floor(this.state.numResults / 60 *0.08) + 1).toString();
    const noResults = this.buildNoResults(this.props.search_val);
    const loading = this.state.loading && <LoadingSpinner loadingType={this.state.loadingType} est_time={est_time}/>;
    const error = this.state.error && "An Error occurred while searching.";
    let renderThis = this.buildSearchResults(allResults);

    if (this.state.loading) renderThis = loading;
    if (this.state.noResults) renderThis = noResults;
    if (this.state.error) renderThis = error;

    return (
        <div className="SearchPage_SearchPage">
          {this.buildSearchHeader()}
          <hr style={{color:"rgba(0, 0, 0, 0.25)", marginLeft:40, marginRight:100,marginTop:30}}/>
          <div className="SearchPage_SearchContent">
            {renderThis}
            {this.state.previewStatus? this.buildResultPreview() : <></>}
          </div>
        </div>
    );
  }
}

function mapStateToProps(state,props) {
  return{
    exactSearch: true,
    search_val: props.search_val || state.searchSlice.search_val,
    search_field: props.search_field || state.searchSlice.search_field,
    route: props.route || state.searchSlice.route
  }
}



export default connect(mapStateToProps)(SearchPage);
